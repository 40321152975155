import { func, bool, shape, string, number } from "prop-types"
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import StripeTestCards from "source/registrations/StripeTestCards"

const StripeForm = ({
  responsiblePerson,
  amount,
  onPaymentSave,
  onIsProcessing,
  onIsCaptureProcessing = () => {},
  onError,
  isTestMode = false,
  useStripeWebhooks = false,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (amount < 50) {
      return onError("Amount must be greater than $0.50")
    }

    const { error: elementError } = await elements.fetchUpdates()

    if (elementError) {
      return onError(elementError.message)
    }

    onIsProcessing(true)
    if (useStripeWebhooks) onIsCaptureProcessing(true)

    const { error, paymentIntent: response } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: responsiblePerson.name,
            email: responsiblePerson.emailAddress,
          },
        },
      },
    })

    if (error) {
      onError(error.message)
    } else {
      onError(null)
      if (useStripeWebhooks) return
      await onPaymentSave(response)
    }

    onIsProcessing(false)
  }

  const options = {
    fields: {
      billingDetails: {
        name: "never",
        email: "never",
      },
    },
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
    paymentMethodOrder: ["apple_pay", "google_pay", "card"],
  }

  return (
    <div>
      {isTestMode && <StripeTestCards />}
      <form id="paymentForm" className="ta-c" onSubmit={handleSubmit}>
        <PaymentElement options={options} />
      </form>
    </div>
  )
}

StripeForm.propTypes = {
  responsiblePerson: shape({
    name: string.isRequired,
    emailAddress: string.isRequired,
  }).isRequired,
  amount: number,
  onPaymentSave: func.isRequired,
  onIsProcessing: func.isRequired,
  onIsCaptureProcessing: func,
  onError: func.isRequired,
  isTestMode: bool,
  useStripeWebhooks: bool,
}

export default StripeForm
