import {
  useQuery,
  splitQueryParams,
  queryFn,
  useQueryClient,
} from "source/registrations/utils/queryCache"

const FIELDS = {
  RegistrationInvoice: [
    "total",
    "subtotal",
    "total_paid",
    "total_refunded",
    "balance_due",
    "refund_due",
    "has_refund_due",
    "has_balance_due",
    "payments",
    "refunds",
    "balance_due_cents",
    "minimum_due",
    "minimum_due_cents",
  ],
}

const queryOptions = (registrationId, options = {}) => {
  const path = `/registrations/v2/registrations/${registrationId}/registration_invoice`
  const { params, queryOptions } = splitQueryParams(options)

  return {
    suspense: false,
    queryFn: queryFn,
    queryKey: [
      path,
      {
        fields: FIELDS,
        ...params,
      },
    ],
    ...queryOptions,
  }
}

export const useInvalidateRegistrationInvoice = (registrationId) => {
  const queryClient = useQueryClient()

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: queryOptions(registrationId).queryKey,
    })
  }
  return invalidate
}

export const useRegistrationInvoice = (registrationId, options) =>
  useQuery(queryOptions(registrationId, options))
