import { node, shape, string } from "prop-types"
import { useCurrentGroup } from "../hooks/useCurrentGroup"
import { useFirstPageOfTags, useTagGroupForTag } from "../hooks/useTags"
import { Grid, Heading } from "@planningcenter/doxy-web"
import { HeroImage } from "source/shared/components"

export function HeaderWithoutTags() {
  return (
    <HeaderWrapper>
      <HeaderImage />
      <NameAndTagsWrapper>
        <GroupName />
      </NameAndTagsWrapper>
    </HeaderWrapper>
  )
}

export function Header() {
  const { data: tags } = useFirstPageOfTags()
  const hasPublishedTags = tags.length > 0

  return (
    <HeaderWrapper>
      <HeaderImage />
      <NameAndTagsWrapper>
        <GroupName />
        {hasPublishedTags && <Tags />}
      </NameAndTagsWrapper>
    </HeaderWrapper>
  )
}

function HeaderWrapper({ children }) {
  return (
    <div className="mb-2@sm mb-4@md">
      <Grid columns={[1, { md: 2 }]} gap={[0, { md: 4 }]}>
        {children}
      </Grid>
    </div>
  )
}
HeaderWrapper.propTypes = { children: node }

function NameAndTagsWrapper({ children }) {
  return (
    <Grid columns={1} gap={[0, { sm: 2 }]}>
      <div className="d-f fd-c jc-c g-2">{children}</div>
    </Grid>
  )
}
NameAndTagsWrapper.propTypes = { children: node }

function HeaderImage() {
  const { data: group } = useCurrentGroup()
  const src = group.attributes.header_image.medium
  const alt = `Logo for ${group.attributes.name}`

  return <HeroImage {...{ alt, src }} />
}

function GroupName() {
  const {
    data: {
      attributes: { name },
    },
  } = useCurrentGroup()
  return <Heading level={1} text={name} />
}

function Tags() {
  const { data: tags } = useFirstPageOfTags()

  return (
    <div className="d-f g-4p fw-w">
      {tags.map((tag) => (
        <Tag key={tag.id} tag={tag} />
      ))}
    </div>
  )
}

function Tag({ tag }) {
  const tagGroup = useTagGroupForTag(tag)

  return (
    <span className="badge">
      <strong>{tagGroup.attributes.name}:</strong> {tag.attributes.name}
    </span>
  )
}
Tag.propTypes = {
  tag: shape({
    attributes: shape({
      name: string.isRequired,
    }).isRequired,
    relationships: shape({
      tag_group: shape({
        data: shape({
          type: string.isRequired,
          id: string.isRequired,
        }).isRequired,
      }),
    }).isRequired,
  }).isRequired,
}
