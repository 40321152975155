import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { SeriesCardList } from "source/publishing/sermons/Channel"
import { LimitedInfiniteScroll } from "source/shared/components"
import { sessionApiClient } from "@planningcenter/cc-api-client"

SeriesLibrary.propTypes = {
  channelId: PropTypes.string.isRequired,
  queryParams: PropTypes.object,
}

export function SeriesLibrary({ channelId, queryParams = {} }) {
  const perPage = 30
  const loadManuallyAfterThisOffset = 60
  const [offset, setOffset] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [records, setRecords] = useState([])

  const loadMore = () => {
    setHasMore(false)
    setOffset((previousOffset) => previousOffset + perPage)
  }

  const allParams = Object.assign({}, queryParams, {
    per_page: perPage,
    offset,
  })
  const queryString = new URLSearchParams(allParams).toString()

  useEffect(() => {
    setLoadingMore(true)
    sessionApiClient
      .get(`/publishing/v2/channels/${channelId}/series?${queryString}`)
      .then((payload) => {
        setLoadingMore(false)
        setHasMore(!!payload.links.next)
        setRecords((prev) => [...prev, ...payload.data])
      })
      .catch((error) => {
        setLoadingMore(false)
        console.log(`There was an error fetching more series: ${error}`) // eslint-disable-line no-console
      })
  }, [queryString])

  return (
    <LimitedInfiniteScroll
      loadMoreButtonText="View more"
      keepLoadingOnScroll={offset < loadManuallyAfterThisOffset}
      hasMore={hasMore}
      loadMore={loadMore}
      loadingMore={loadingMore}
    >
      <SeriesCardList series={records} />
    </LimitedInfiniteScroll>
  )
}
