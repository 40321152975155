import PropTypes from "prop-types"
import parse, { domToReact } from "html-react-parser"
import { useParams } from "react-router-dom"

import { useApiSuspenseQuery } from "source/shared/hooks/useApiSuspenseQuery"
import { NotesContext } from "./NotesContext"
import {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useMutation } from "@tanstack/react-query"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useDebounce } from "source/shared/hooks/useDebounce"
import { useUpdateEffect } from "source/shared/hooks/useUpdateEffect"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { Button } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { Loading } from "source/shared/components"

export function SuspenseNotes() {
  return (
    <Suspense fallback={<Loading />}>
      <Notes />
    </Suspense>
  )
}

export function Notes() {
  const { episodeId } = useParams()
  const { templateHtml } = Notes.useNoteState({ episodeId })

  const reactifiedTemplate = useMemo(
    () => Notes.reactifyTemplate(templateHtml),
    [templateHtml],
  )

  return (
    <div style={{ marginTop: "3em" }}>
      <Notes.ContextProvider episodeId={episodeId}>
        <NotesHeader />
        {reactifiedTemplate}
      </Notes.ContextProvider>
    </div>
  )
}
Notes.ContextProvider = function ContextProvider({ children, episodeId }) {
  const [annotation, setAnnotation] = useState({ id: "root-0" })
  const [revealBlanks, setRevealBlanks] = useState(false)

  const { notes, setNotes } = Notes.useNoteState({ episodeId })
  const onChange = (id, value) => setNotes((prev) => ({ ...prev, [id]: value }))

  return (
    <NotesContext.Provider
      value={{
        annotation,
        setAnnotation,
        onChange,
        revealBlanks,
        setRevealBlanks,
        values: notes,
      }}
    >
      {children}
    </NotesContext.Provider>
  )
}
Notes.ContextProvider.displayName = "Notes.ContextProvider"
Notes.ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  episodeId: PropTypes.string.isRequired,
}
Notes.findSplitIndex = function (children) {
  let index = children.findIndex(
    (child) =>
      child.type === "tag" && (child.name === "ul" || child.name === "ol"),
  )
  if (index === -1) {
    index = children.length
  }
  while (
    index > 1 &&
    children[index - 1] &&
    children[index - 1].name === "br"
  ) {
    index--
  }
  return index
}
Notes.reactifyTemplate = function (templateHtml) {
  let iterator = 0
  const options = {
    transform(node, dom, index) {
      if (
        node.type === "span" &&
        node.props?.className === "fill-in-the-blank"
      ) {
        const id = node.props.id
        const templateValue = node.props.content

        return (
          <NoteInput
            id={id}
            annotationId={`root-${index}`}
            templateValue={templateValue}
          />
        )
      }

      if (dom.parent === null && node.type !== "ul" && node.type !== "ol") {
        const id = node.props.id || `root-${index}`
        return <NoteParentNode node={node} id={id} />
      }

      return node
    },

    replace(node) {
      iterator++
      const { children } = node

      if (node.type === "tag" && node.name === "li") {
        const splitIndex = Notes.findSplitIndex(children)

        const childrenBeforeUl = children.slice(0, splitIndex)
        const childrenAfterUl = children.slice(splitIndex)
        const id = `li-${iterator}`

        return (
          <NoteListNode
            id={id}
            beforeNodes={domToReact(childrenBeforeUl, options)}
            afterNodes={domToReact(childrenAfterUl, options)}
          />
        )
      }
    },
  }

  return parse(templateHtml, options)
}
Notes.useNoteState = function ({ episodeId }) {
  const { data: noteData } = useApiSuspenseQuery({
    path: `/publishing/v2/episodes/${episodeId}/note`,
    queryClientOptions: {
      select: ({ data }) => data?.attributes,
      staleTime: Infinity,
    },
  })
  const { data: noteTemplateHtml } = useApiSuspenseQuery({
    path: `/publishing/v2/episodes/${episodeId}/note_template`,
    queryClientOptions: {
      select: ({ data }) => data.attributes.template,
      staleTime: Infinity,
    },
  })
  const { mutate: noteMutate } = useMutation({
    mutationFn: (attributes) => {
      sessionApiClient
        .patch(`/publishing/v2/episodes/${episodeId}/note`, {
          data: { attributes },
        })
        .catch(console.error)
    },
  })

  const [localNotes, setLocalNotes] = useLocalStorage(`notes-${episodeId}`, {})
  const templateHtml =
    noteData?.template || localNotes?.template || noteTemplateHtml || ""
  const initialNotes = (noteData ? noteData.content : localNotes.content) || {}
  const [notes, setNotes] = useState(initialNotes)

  const debouncedNotes = useDebounce(notes, 300)
  useUpdateEffect(() => {
    const data = { content: notes, template: templateHtml }
    noteData ? noteMutate(data) : setLocalNotes(data)
  }, [debouncedNotes, noteMutate])

  return { templateHtml, notes, setNotes }
}

function NoteParentNode({ id, node }) {
  const {
    onChange: contextOnChange,
    annotation,
    setAnnotation,
    values,
  } = useContext(NotesContext)
  const value = values[id] || ""
  const isOpen = annotation?.id === id || value !== ""
  const toggle = (event) => {
    const { target } = event
    // don't toggle if the user is typing/clicking in a text input
    if (target.type === "text" || target.type === "textarea") return

    event.stopPropagation()

    if (annotation?.id !== id) {
      setAnnotation({ id, autofocus: true })
    } else {
      setAnnotation(null)
    }
  }
  const onChange = (e) => {
    contextOnChange(id, e.target.value)
  }

  return (
    <div style={{ marginBottom: "1rem" }}>
      <div
        tabIndex={0}
        onClick={toggle}
        role="button"
        onKeyDown={(e) =>
          // our accessibility linter recommends we provide a way to
          // "click" this with a keyboard shortcut since it's a div
          (e.code === "Space" || e.code === "Enter") && toggle(e)
        }
        style={{
          borderBottom: isOpen ? "none" : "1px dashed #ddd",
        }}
      >
        {node}
      </div>
      <NoteAnnotation
        isOpen={isOpen}
        onChange={onChange}
        value={value}
        nodeId={id}
      />
    </div>
  )
}
NoteParentNode.displayName = "NoteParentNode"
NoteParentNode.propTypes = {
  id: PropTypes.string.isRequired,
  node: PropTypes.object.isRequired,
}

function NoteListNode({ id, beforeNodes, afterNodes }) {
  const {
    onChange: contextOnChange,
    annotation,
    setAnnotation,
    values,
  } = useContext(NotesContext)
  const value = values[id] || ""
  const isOpen = annotation?.id === id || value !== ""
  const toggle = (event) => {
    const { target } = event
    // don't toggle if the user is typing/clicking in a text input
    if (target.type === "text" || target.type === "textarea") return

    event.stopPropagation()

    if (annotation?.id !== id) {
      setAnnotation({ id, autofocus: true })
    } else {
      setAnnotation(null)
    }
  }
  const onChange = (e) => {
    contextOnChange(id, e.target.value)
  }
  return (
    <li>
      <div
        tabIndex={0}
        onClick={toggle}
        role="button"
        onKeyDown={(e) =>
          // our accessibility linter recommends we provide a way to
          // "click" this with a keyboard shortcut since it's a div
          (e.code === "Space" || e.code === "Enter") && toggle(e)
        }
        style={{
          borderBottom: isOpen ? "none" : "1px dashed #ddd",
        }}
      >
        {beforeNodes}
      </div>
      <NoteAnnotation
        isOpen={isOpen}
        onChange={onChange}
        value={value}
        nodeId={id}
      />
      {afterNodes}
    </li>
  )
}
NoteListNode.displayName = "NoteListNode"
NoteListNode.propTypes = {
  id: PropTypes.string.isRequired,
  beforeNodes: PropTypes.node.isRequired,
  afterNodes: PropTypes.node,
}

function NoteAnnotation({ isOpen, onChange, nodeId, value }) {
  const { annotation } = useContext(NotesContext)
  const textareaRef = useRef(null)
  useEffect(() => {
    if (isOpen && annotation?.autofocus && annotation?.id === nodeId) {
      textareaRef.current.focus()
      textareaRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [isOpen, annotation])
  return (
    <div
      css={{
        display: "grid",
        gridTemplateRows: isOpen ? "1fr" : "0fr",
        transition: "all 0.3s ease-in-out",
        opacity: isOpen ? 1 : 0,
        marginTop: isOpen ? "1rem" : 0,
      }}
    >
      <div
        data-replicated-value={value}
        css={{
          overflow: "hidden",
          display: "grid",
          ":after": [
            styles.growTextarea,
            {
              content: 'attr(data-replicated-value) " "',
              whiteSpace: "pre-wrap",
              visibility: "hidden",
            },
          ],
        }}
      >
        <textarea
          ref={textareaRef}
          placeholder="Add your notes here"
          value={value}
          onChange={onChange}
          css={[
            styles.growTextarea,
            {
              resize: "none",
              overflow: "hidden",
              "::placeholder": {
                transition: "opacity 0.3s ease-in-out",
                opacity: isOpen ? 1 : 0,
              },
            },
          ]}
          rows={1}
        />
      </div>
    </div>
  )
}
NoteAnnotation.displayName = "NoteAnnotation"
NoteAnnotation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const styles = {
  growTextarea: {
    border: "none",
    padding: 0,
    font: "inherit",
    fontSize: "16px",
    color: "var(--color-tint2)",
    gridArea: "1 / 1 / 2 / 2",
    outlineColor: "transparent !important",
    boxShadow: "none !important",
  },
}

function NoteInput({ annotationId, id, templateValue = "" }) {
  const { onChange, revealBlanks, setAnnotation, values } =
    useContext(NotesContext)
  const textWidthPadding = 20
  const value = revealBlanks ? templateValue : values[id] || ""

  return (
    <input
      disabled={revealBlanks}
      onFocus={() => setAnnotation({ id: annotationId, autofocus: false })}
      onChange={(e) => {
        onChange(id, e.target.value)
      }}
      value={value}
      css={{
        border: "none",
        backgroundColor: "var(--color-tint6)",
        borderRadius: 4,
        fontSize: "inherit",
        width: getTextWidth(templateValue) + textWidthPadding + "px",
      }}
    />
  )
}
NoteInput.displayName = "NoteInput"
NoteInput.propTypes = {
  annotationId: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  templateValue: PropTypes.string,
}
function getTextWidth(text) {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  context.font = getComputedStyle(document.body).font

  return context.measureText(text).width
}

function NotesHeader() {
  const { revealBlanks, setRevealBlanks } = useContext(NotesContext)
  const revealButtonText = revealBlanks
    ? "Hide all blanks"
    : "Reveal all blanks"

  return (
    <div
      className="p-2"
      style={{ borderBottom: "1px solid var(--color-tint5)" }}
    >
      <Button
        text={
          <>
            {revealButtonText}
            <Icon symbol="services#eye" aria-hidden className="ml-1" />
          </>
        }
        size="md"
        variant="outline"
        onClick={() => setRevealBlanks((prev) => !prev)}
      />
    </div>
  )
}
