import { useState } from "react"
import { AlertMessage } from "source/shared/components"
import { useRegistration } from "source/registrations/hooks/useRegistration"
import { NarrowLayout } from "source/Layout"
import Help from "source/registrations/registrations/Confirmation/Help"
import { Heading, Button } from "@planningcenter/doxy-web"
import useQueryString from "source/shared/hooks/useQueryString"
import { useParams, Link } from "react-router-dom"
import Question from "source/registrations/questions/Question"
import { useAnswers } from "source/registrations/hooks/useAttendeeInfoFormAnswers"
import { Avatar } from "source/shared/components"
import { sortBy } from "lodash"
import { useQuery, queryFn } from "source/registrations/utils/queryCache"
import BlankState from "source/groups/BlankState"
import { useNavigate } from "react-router-dom"
import useUnsavedChangesAlert from "source/shared/hooks/useUnsavedChangesAlert"

const AttendeeInformationForm = () => {
  const { registrationId, attendeeId } = useParams()
  const [changes, setChanges] = useState(false)
  const { errors: registrationErrors, data: registration } =
    useRegistration(registrationId)
  const navigate = useNavigate()
  const [errors, setErrors] = useState(registrationErrors)

  const { data: initialQuestions } = useQuery({
    queryFn,
    queryKey: [
      `/registrations/v2/registrations/${registrationId}/attendees/${attendeeId}/form_questions`,
    ],
  })
  const sortedQuestions = sortBy(initialQuestions, "position")

  const { data: initialAnswers } = useQuery({
    queryFn,
    queryKey: [
      `/registrations/v2/registrations/${registrationId}/attendees/${attendeeId}/answers`,
    ],
  })

  useUnsavedChangesAlert(changes)

  const attendee = registration.attendees.filter((attendee) => {
    return attendee.id == attendeeId
  })[0]

  const {
    answers,
    questions,
    handleChange,
    handleSave,
    handleReset,
    error,
    isLoading,
  } = useAnswers({
    answers: initialAnswers,
    questions: sortedQuestions,
    registerableId: registrationId,
    attendeeId: attendeeId,
    eventId: registration.eventId,
  })

  const requiredQuestionAnswered = questions
    .filter((q) => q.required && q.questionGroup == "attendee_form")
    .every((q) => {
      if (!answers[q.id]) {
        return false
      } else if (q.kind === "singlecheckbox") {
        return answers[q.id].answers[0] === "true"
      }

      return answers[q.id]?.answers.length > 0
    })

  const canSave =
    !isLoading &&
    Object.values(answers).some((answer) => answer.isModified) &&
    requiredQuestionAnswered

  const handleCancel = () => {
    handleReset()
    setChanges(false)
    navigate(`/registrations/registrations/${registrationId}/confirmation`)
  }

  const handleAnswerSave = async () => {
    const response = await handleSave()

    if (response.errors) {
      setErrors(response.errors)
    } else {
      setChanges(false)
      registration.invalidate()
      navigate(`/registrations/registrations/${registrationId}/confirmation`)
    }
  }

  const handleChangeMade = (questionId, value) => {
    handleChange(questionId, value)
    setChanges(true)
  }

  const [params] = useQueryString()
  const newRegistrationUrl = `${registration.event.newRegistrationUrl}${
    params.source ? `?source=${params.source}` : ""
  }`

  if (errors) {
    return <AlertMessage>{errors[0].detail}</AlertMessage>
  }

  if (registration.canceledOn) {
    return (
      <AlertMessage type="info" icon="general#info-circle">
        This registration has been cancelled. Do you need to{" "}
        <a href={newRegistrationUrl}>register again</a>?
      </AlertMessage>
    )
  }

  return questions.length ? (
    <NarrowLayout>
      <div className={"m-2 d-f ai-c jc-sb fw-500"}>
        <Heading level={2} size={3} text="Attendee Information Form" />
        <div className={`d-f ai-c`}>
          <div className="avatar mr-1 w-4 h-4 t-3p">
            <Avatar
              url={attendee?.person?.avatarUrl}
              alt={attendee?.person?.name}
            />
          </div>
          <h2 className={`h4`}>{attendee?.person?.name}</h2>
        </div>
      </div>

      {error && (
        <AlertMessage type="error" icon="general#exclamation-triangle">
          {error}
        </AlertMessage>
      )}
      <div
        className="p-3 d-f fd-c g-3"
        css={{ backgroundColor: "var(--color-tint9)" }}
      >
        {questions.map((question) => {
          if (question.questionGroup !== "attendee_form") {
            return
          }

          return question.kind === "section_header" ? (
            <div
              key={question.id}
              className="mt-2"
              css={{
                borderBottom: "1px solid var(--color-tint5)",
              }}
            >
              <Question
                question={question}
                answers={answers}
                onChange={handleChangeMade}
                isEditing={true}
                removeContainer={true}
              />
            </div>
          ) : (
            <Question
              key={question.id}
              question={question}
              answers={answers}
              onChange={handleChangeMade}
              isEditing={true}
              removeContainer={true}
            />
          )
        })}
      </div>
      <div className={"mt-2 d-f ai-c jc-fe g-2 fw-500"}>
        <Button onClick={handleCancel} text="Cancel" variant="naked" />
        <Button onClick={handleAnswerSave} text={"Save"} disabled={!canSave} />
      </div>
      <Help registration={registration} />
    </NarrowLayout>
  ) : (
    <BlankState icon="churchCenter#registrations">
      <Heading
        level={1}
        size={3}
        color="tint2"
        text="No additional attendee info required"
      />
      <p className="mb-2">
        There is no attendee information form to fill out for this signup.
      </p>
      <Link to=".." className="mr-1 minor-btn secondary-btn btn">
        Return to confirmation
      </Link>
    </BlankState>
  )
}

AttendeeInformationForm.propTypes = {}

export default AttendeeInformationForm
