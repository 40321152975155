import { useState, useEffect, useCallback } from "react"
import { debounce } from "lodash"

export const BREAKPOINTS = {
  XXL: 1600,
  XL: 1200,
  LG: 960,
  MD: 720,
  SM: 600,
  XS: 480,
}

export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const handleResize = useCallback(
    debounce(() => {
      setScreenWidth(window.innerWidth)
    }, 10),
    [setScreenWidth],
  )

  useEffect(() => {
    window.addEventListener("resize", handleResize, { passive: true })

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize])

  return screenWidth
}
