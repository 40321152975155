import { useParams } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { Library } from "source/publishing/sermons/Channel"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { useQRCode } from "source/shared/QRCode"
import { css } from "@emotion/react"
import { Heading } from "@planningcenter/doxy-web"
import { Error404 } from "source/shared/components/Error404"

const fixSeriesImageOnMobile = css`
  @media (max-width: 719px) {
    margin-top: -1.5rem;
  }
`

export function Series() {
  const { channelId, seriesId } = useParams()
  const response = useApiRead(
    `/publishing/v2/channels/${channelId}/series/${seriesId}?include=channel`,
  )

  if (response.errors?.[0]?.status === "404") return <Error404 />

  const series = response.data
  const { art, description, title } = series.attributes
  const channel = response.included[0]
  const imageURL = series.attributes.art.attributes.variants.medium

  useQRCode()

  return (
    <>
      <OpenGraphTags
        title={title}
        imageUrl={art.attributes.variants.small}
        description={description}
      />

      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl={`/channels/${channelId}`}
          pageText={channel.attributes.name}
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText={series.attributes.title} isActive />
      </BreadcrumbNavigation>
      <div className="d-f@md fd-r mb-3@md">
        <div className="w-48%@md item-show__image" css={fixSeriesImageOnMobile}>
          <img className="d-b aspect-16-9" alt={`Series art`} src={imageURL} />
        </div>

        <div className="f-1 d-f fd-c g-4p g-1@md pl-6@md mb-2">
          <Heading level={1} text={series.attributes.title} />
          <p>{series.attributes.description}</p>
        </div>
      </div>
      <Library
        queryParams={{
          "where[series_id]": seriesId,
          filter: "published_to_library",
          order: "-published_to_library_at",
        }}
      />
    </>
  )
}
