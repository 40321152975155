import { useApiReadEntireCollection } from "source/shared/SessionApiResource"

const SCHOOL_OPTIONS_PATH = "/people/v2/school_options"

export function useAllSchools() {
  const responses = useApiReadEntireCollection(SCHOOL_OPTIONS_PATH)

  return responses.reduce((acc, { data }) => {
    return [...acc, ...data]
  }, [])
}
