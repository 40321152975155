import { useContext, useEffect, useState } from "react"
import { bool, node, object } from "prop-types"
import { useNavigate, useParams } from "react-router-dom"
import {
  Channel,
  MessageList,
  MessageInput,
  useChatContext as useStreamChatContext,
} from "stream-chat-react"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { Heading } from "@planningcenter/doxy-web"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Icon } from "source/shared/components"
import {
  createResource,
  useCacheRead,
} from "source/shared/react-cache-but-its-a-context"
import { useApiRead } from "source/shared/SessionApiResource"
import { CurrentGroupContext } from "source/groups/my/groups"
import { ActionBar } from "source/shared/components"
import { reactionData } from "source/groups/my/groups/conversations/reactionData"
import { css } from "@emotion/react"
import { usePlatformUiHeight } from "source/groups/hooks/usePlatformUiHeight"
import { init, SearchIndex } from "emoji-mart"
import data from "@emoji-mart/data"
import { EmojiPicker } from "stream-chat-react/emojis"

init({ data })

const MAX_FILE_SIZE = 52428800 // 50MB
const ALLOWED_FILE_TYPES_FOR_UPLOAD = [
  ".3gp",
  ".apng",
  ".avi",
  ".avif",
  ".flv",
  ".gif",
  ".heic",
  ".hvec",
  ".jfif",
  ".jpeg",
  ".jpg",
  ".mov",
  ".mp4",
  ".pjp",
  ".pjpeg",
  ".png",
  ".svg",
  ".webm",
  ".webp",
  ".wmv",
  ".tiff",
]

export function ConversationsShow() {
  const params = useParams()
  const { data: conversation } = useApiRead(
    `/chat/v2/me/conversations/${params.conversationId}`,
  )
  const channel = useStreamChannel(
    conversation.relationships.stream_channel.data.id,
  )
  const muted = useIsChannelMuted(channel)
  const [frozen, setFrozen] = useState(channel.data.frozen)
  const canSendMessage = !frozen

  const customFileUploadRequest = (file, channel) => {
    const isImage = file.type.includes("image")
    const isVideo = file.type.includes("video")

    if (file.size > MAX_FILE_SIZE) {
      throw new Error("Please limit the size of your image or video to 50 MB")
    } else if (isImage) {
      return channel.sendImage(file)
    } else if (isVideo) {
      return channel.sendFile(file)
    } else {
      throw new Error(
        "Please limit your uploads to images and videos that are under 50 MB",
      )
    }
  }

  useEffect(() => {
    const eventListener = channel.on("channel.updated", (e) =>
      setFrozen(e.channel.frozen),
    )

    return () => eventListener.unsubscribe()
  }, [channel])

  useResetActiveChannelOnUnmount()

  return (
    <>
      <div className="d-f jc-sb ai-c mb-2">
        <Heading level={1} size={3} text={conversation.attributes.title} />
        {muted && (
          <span className="c-tint4 ml-1 mr-a" title="Muted">
            <Icon
              symbol="general#inactive"
              title="Muted"
              style={{ marginRight: "auto" }}
            />
          </span>
        )}
        <OptionsMenu channel={channel} frozen={frozen} />
      </div>
      <ChannelHeight>
        <Channel
          acceptedFiles={ALLOWED_FILE_TYPES_FOR_UPLOAD}
          channel={channel}
          EmojiPicker={EmojiPicker}
          reactionOptions={reactionData}
        >
          <MessageList messageActions={["edit", "delete", "react"]} />
          {canSendMessage ? (
            <MessageInput
              grow
              disableMentions
              emojiSearchIndex={SearchIndex}
              doImageUploadRequest={customFileUploadRequest}
              doFileUploadRequest={customFileUploadRequest}
            />
          ) : (
            <ConversationIsFrozenPane />
          )}
        </Channel>
      </ChannelHeight>
    </>
  )
}

ChannelHeight.propTypes = {
  children: node.isRequired,
}

function ChannelHeight({ children }) {
  const heightOffset = usePlatformUiHeight()
  const heightOffsetMobile = heightOffset - 24

  return (
    <div
      css={css`
        height: calc(100vh - ${heightOffsetMobile}px);

        @media (min-width: 720px) {
          height: calc(100vh - ${heightOffset}px);
        }
      `}
    >
      {children}
    </div>
  )
}

function ConversationIsFrozenPane() {
  return (
    <ActionBar.Pane>
      <ActionBar.Column>
        <ActionBar.Description>
          Sending messages and reactions are disabled.
        </ActionBar.Description>
      </ActionBar.Column>
    </ActionBar.Pane>
  )
}

OptionsMenu.propTypes = {
  channel: object.isRequired,
  frozen: bool.isRequired,
}

function OptionsMenu({ channel, frozen }) {
  const navigate = useNavigate()
  const params = useParams()
  const group = useContext(CurrentGroupContext)
  const muted = useIsChannelMuted(channel)
  const canFreeze = channel.data.own_capabilities.includes("freeze-channel")
  const { canUpdate, canDelete } = useMemberAbility(channel.id)

  function editConversation() {
    navigate(`${group.base_path}/conversations/${params.conversationId}/edit`)
  }

  function deleteConversation() {
    // Using a setTimeout prevents an error from StreamChat client:
    // You can't use a channel after client.disconnect() was called
    setTimeout(() =>
      sessionApiClient.del(
        `/chat/v2/me/conversations/${params.conversationId}`,
      ),
    )
    navigate(`${group.base_path}/conversations`)
  }

  function toggleMute() {
    if (muted) {
      channel.unmute()
    } else {
      channel.mute()
    }
  }

  function toggleFreeze() {
    if (!canFreeze) return

    // this doesn't handle any errors since we don't know what the UX should be with an error yet.
    channel.updatePartial({ set: { frozen: !frozen } })
  }

  const disclaimerFrozenStatus = frozen ? "Enables" : "Disables"

  return (
    <Menu>
      <MenuButton className="minor-btn secondary-btn btn">
        Options
        <span className="fs-6 pl-4p dropdown-trigger__icon">
          <Icon symbol="general#down-chevron" />
        </span>
      </MenuButton>
      <MenuList>
        <MenuItem onSelect={toggleMute}>{muted ? "Unmute" : "Mute"}</MenuItem>
        {canUpdate && <MenuItem onSelect={editConversation}>Edit</MenuItem>}
        {canFreeze && (
          <MenuItem onSelect={toggleFreeze}>
            {frozen ? "Unfreeze conversation" : "Freeze conversation"}
            <div className="fs-5 c-tint2">
              {disclaimerFrozenStatus} sending messages and reactions.
            </div>
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem onSelect={deleteConversation} className="c-ruby">
            Delete
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}

const StreamChannel = createResource(
  (client, id) =>
    client.queryChannels({ id: { $eq: id } }).then((results) => results[0]),
  (_client, id) => `StreamChannel-${id}`,
)

function useStreamChannel(id) {
  const { client } = useStreamChatContext()
  return useCacheRead(StreamChannel, client, id)
}

function useResetActiveChannelOnUnmount() {
  const { setActiveChannel } = useStreamChatContext()

  useEffect(() => () => setActiveChannel(null))

  return null
}

function useIsChannelMuted(channel) {
  const { client } = useStreamChatContext()
  const [muted, setMuted] = useState(channel.muteStatus().muted)

  useEffect(() => {
    const handleEvent = () => setMuted(channel.muteStatus().muted)

    client.on("notification.channel_mutes_updated", handleEvent)
    return () => client.off("notification.channel_mutes_updated", handleEvent)
  }, [channel, client, muted])

  return muted
}

function useMemberAbility(conversationId) {
  const { data: response } = useApiRead(
    `/chat/v2/me/conversations/${conversationId}/member_ability`,
  )
  const ability = response.attributes

  return { canUpdate: ability.can_update, canDelete: ability.can_delete }
}
