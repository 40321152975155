import PropTypes from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { CardGrid, Icon, Skeleton } from "source/shared/components"
import GalleryEvent from "source/calendar/list/GalleryEvent"
import calendarEventDetails from "source/calendar/utils/calendarEventDetails"
import { useMomentInTimeZone } from "source/calendar/hooks/useMomentInTimeZone"
import moment from "moment"
import { useQuery } from "@tanstack/react-query"
import qs from "query-string"
import { useMemo } from "react"
import { useConfiguration as useDateTimeConfiguration } from "source/shared/DatetimeFmt"
import {
  GALLERY_VIEW_EVENT_FIELDS,
  LOCATION_BASED_EVENT_INCLUDES,
  getListEventTime,
} from "source/calendar/hooks/useCalendarEvents"
import EventListing from "source/calendar/list/EventListing"
import { EventDate } from "source/calendar/event/Event"
import { NoEventsPlaceholder } from "source/calendar/list/List"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"

const MAX_EVENTS_TO_RENDER = 6

function useEventsQuery(params) {
  const dateTimeConfiguration = useDateTimeConfiguration()
  const momentInTZ = useMomentInTimeZone()

  return useQuery({
    queryKey: [params],
    queryFn: useEventsQuery.queryFn,
    select: useMemo(
      () => useEventsQuery.selector(dateTimeConfiguration, momentInTZ),
      [dateTimeConfiguration, momentInTZ],
    ),
    suspense: false,
    retry: false,
  })
}
useEventsQuery.queryFn = function ({ queryKey }) {
  const search = qs.stringify(queryKey[0], { arrayFormat: "comma" })
  return sessionApiClient.get(`/calendar/v2/events?${search}`)
}
useEventsQuery.selector = function (dateTimeConfiguration, momentInTZ) {
  return function (data) {
    const { data: events, included, meta } = data

    let detailedEvents = events.map((event) =>
      calendarEventDetails({ data: event, included }),
    )

    detailedEvents.forEach((it) => {
      it.eventDay = moment(it.eventDate).format("YYYY-MM-DD")
    })

    detailedEvents.forEach((it) => {
      it.eventTime = getListEventTime({
        dateTimeConfiguration,
        startsAt: it.initialStartsAt
          ? momentInTZ(it.initialStartsAt)
          : momentInTZ(it.startsAt),
        endsAt: momentInTZ(it.endsAt),
        isAllDay: !!it.allDayEvent,
        isMultiDay: !!it.multiDayEvent,
        forDay: it.initialStartsAt ? it.startsAt : null,
        momentInTZ,
      })
    })

    return {
      events: detailedEvents,
      meta: { hasMore: meta.total_count > detailedEvents.length },
    }
  }
}

function buildParams(block, momentInTZ) {
  // ---- shared query settings ----

  let search = {
    include: LOCATION_BASED_EVENT_INCLUDES,
    per_page: MAX_EVENTS_TO_RENDER,
    "fields[Event]": GALLERY_VIEW_EVENT_FIELDS.join(),
    utm_campaign: "pco_content_block",
    utm_medium: "web",
    utm_source: "church_center",
  }

  // ---- three main query types: featured, image (gallery) view, list view ----

  if (block.attributes.only_featured) {
    search.filter = "upcoming,first_occurrence"
    search.order = "visible_starts_at"
    search["where[featured]"] = 1
  }

  if (block.attributes.layout === "gallery") {
    search.filter = "upcoming,first_occurrence"
    search.order = "-featured,visible_starts_at"
  }

  if (!block.attributes.only_featured && block.attributes.layout === "list") {
    if (block.attributes.only_next_instance) {
      search.filter = "upcoming,first_occurrence"
    } else {
      search.filter = "upcoming"
    }
  }

  // ---- refinements regardles of query type ----

  if (block.attributes.campus_tag_id) {
    search["where[campus]"] = block.attributes.campus_tag_id
  }

  if (block.attributes.category_tag_id) {
    search["where[category]"] = block.attributes.category_tag_id
  }

  switch (block.attributes.limit_by) {
    case "count": {
      search.per_page = block.attributes.limit_count
      break
    }

    case "days": {
      const endDate = momentInTZ()
        .endOf("day")
        .add(block.attributes.limit_days, "days")

      search["where[starts_at][lte]"] = endDate.toISOString()
      break
    }
  }

  return search
}

CalendarEvents.propTypes = {
  block: PropTypes.object.isRequired,
}
export function CalendarEvents(block) {
  const styles = {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: "640px",
    marginBottom: "24px",
    gap: "12px",
  }

  return (
    <div style={styles} className="page-block">
      {block.attributes.headline_enabled && (
        <h2
          style={{
            fontSize: 30,
            textAlign: block.attributes.headline_alignment,
          }}
        >
          {block.attributes.headline}
        </h2>
      )}

      {block.attributes.layout === "gallery" && <GridView block={block} />}

      {block.attributes.layout === "list" && <ListView block={block} />}
    </div>
  )
}

GridView.propTypes = {
  block: PropTypes.object.isRequired,
}
function GridView({ block }) {
  const momentInTZ = useMomentInTimeZone()
  const params = buildParams(block, momentInTZ)

  const { data, isSuccess, isError } = useEventsQuery(params)
  const {
    events,
    meta: { hasMore },
  } = data || { events: [], meta: { hasMore: false } }

  if (isError) {
    return <ErrorState />
  }

  if (!isSuccess) {
    return (
      <CardGrid
        columns={block.attributes.columns_desktop}
        columnsMobile={block.attributes.columns_mobile}
      >
        <Skeleton aspectRatio="308 / 242" />
        <Skeleton aspectRatio="308 / 242" />
        <Skeleton aspectRatio="308 / 242" />
        <Skeleton aspectRatio="308 / 242" />
      </CardGrid>
    )
  }

  if (events.length === 0) {
    return (
      <div className="mt-1">
        <NoEventsPlaceholder filterSelected={false} />
      </div>
    )
  }

  if (block.attributes.only_featured) {
    events.forEach((event) => {
      event.featured = false
    })
  }

  return (
    <>
      {events.length > 1 ? (
        <CardGrid>
          {events.map((event) => (
            <GalleryEvent
              key={event.id}
              {...{
                event,
                layout: "column",
              }}
            />
          ))}
        </CardGrid>
      ) : (
        events.map((event) => (
          <div
            key={event.id}
            style={{
              alignSelf: block.attributes.headline_alignment
                .replace("left", "flex-start")
                .replace("right", "flex-end"),
              maxWidth: "320px",
            }}
          >
            <GalleryEvent
              {...{
                event,
                layout: "column",
              }}
            />
          </div>
        ))
      )}

      {hasMore && <LinkToViewMore block={block} />}
    </>
  )
}

LinkToViewMore.propTypes = {
  block: PropTypes.object.isRequired,
}
function LinkToViewMore({ block }) {
  let params = { view: block.attributes.layout }

  if (block.attributes.campus_tag_id) {
    params.campus = block.attributes.campus_tag_id
  }

  if (block.attributes.category_tag_id) {
    params.category = block.attributes.category_tag_id
  }

  return (
    <div style={{ textAlign: block.attributes.headline_alignment }}>
      <a href={`/calendar?${new URLSearchParams(params).toString()}`}>
        View full calendar
      </a>
    </div>
  )
}

ListView.propTypes = {
  block: PropTypes.object.isRequired,
}
function ListView({ block }) {
  const momentInTZ = useMomentInTimeZone()
  const events = useEventsQuery(buildParams(block, momentInTZ))
  const hasMore = events.data?.meta.hasMore
  const shouldUseNewSignupBadgeLogic = useFlipperFeatureEnabled(
    "ROLLOUT_calendar_new_signups_logic",
  )

  if (events.isError) {
    return <ErrorState />
  }

  if (!events.isSuccess) {
    return <Skeleton.Paragraph />
  }

  if (events.data.events.length === 0) {
    return (
      <div className="mt-1">
        <NoEventsPlaceholder filterSelected={false} />
      </div>
    )
  }

  return (
    <>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          listStyle: "none",
          margin: 0,
          padding: 0,
        }}
      >
        {events.data.events.map((event) => {
          const legacyLogic =
            event.registrationUrl && !event.registrationUrl.announcementOnly
          const newLogic = event.registrationUrl?.canRegister
          const signupsAvailable = shouldUseNewSignupBadgeLogic
            ? newLogic
            : legacyLogic

          return (
            <EventListing
              key={event.id}
              eventDate={momentInTZ(event.startsAt).startOf("day")}
              eventId={event.id}
              eventTitle={event.name}
              eventRecurrence={event.recurrenceDescription}
              eventLocation={
                event.location &&
                (event.location.name || event.location.formattedAddress)
              }
              eventTime={
                <EventDate
                  allDayEvent={event.allDayEvent}
                  endsAt={event.endsAt}
                  multiDayEvent={
                    false /* setting false uses dateStyle === "long" */
                  }
                  startsAt={event.startsAt}
                />
              }
              signupsAvailable={signupsAvailable}
            />
          )
        })}
      </ul>
      {hasMore && <LinkToViewMore block={block} />}
    </>
  )
}

function ErrorState() {
  return (
    <div className="ta-c c-tint2 fs-2">
      <Icon symbol="general#exclamation-triangle" className="mb-1" />
      <div>Error loading events</div>
    </div>
  )
}
