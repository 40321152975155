import { createContext, useContext, useState, useEffect } from "react"
import { useDebouncedCallback } from "source/shared/hooks/useDebounce"

const WindowDimensionsCtx = createContext(null)

const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
    document.documentElement.style.setProperty(
      "--window-inner-height",
      window.innerHeight + "px",
    )
  }
  const debouncedResize = useDebouncedCallback(handleResize, 200)

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", debouncedResize)
    return () => {
      window.removeEventListener("resize", debouncedResize)
    }
  }, [])

  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  )
}

export default WindowDimensionsProvider
export const useWindowDimensions = () => useContext(WindowDimensionsCtx)
