import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  transformResponse,
  transformRequestData,
} from "@planningcenter/api-client"

export const API_VERSION = "2020-06-16"

export { transformResponse, transformRequestData }

const urlParamsToString = (path, options = {}) => {
  const params = Object.keys(options)
    .map((key) => (options[key] ? `${key}=${options[key]}` : null))
    .filter(Boolean)
    .join("&")

  return `${path}${params ? `?${params}` : ""}`
}

export const request = async (method, path, { version, ...attrs } = {}) => {
  try {
    if (!path) throw new Error("You must specify a path")

    const { data, included, ...params } = await transformRequestData(attrs)

    const body = {
      ...(data && { data }),
      ...(included && { included }),
    }

    const response = await sessionApiClient[method](
      urlParamsToString(path, params),
      body,
      { version: version || API_VERSION },
    )

    return response.errors && response.errors[0].code === 204
      ? { data: {} }
      : { ...transformResponse(response), links: response.links }
  } catch (exception) {
    return exception?.errors
      ? exception
      : { errors: [{ detail: exception.toString() }] }
  }
}

export const api = {
  get(path, params) {
    return request("get", path, params)
  },
  post(path, params) {
    return request("post", path, params)
  },
  put(path, params) {
    return request("put", path, params)
  },
  patch(path, params) {
    return request("patch", path, params)
  },
  destroy(path, params) {
    return request("del", path, params)
  },
}
