import { object, node, string } from "prop-types"
import { Elements } from "@stripe/react-stripe-js"
import { AlertMessage } from "source/shared/components"

const StripeWrapper = ({ paymentIntent, error, stripe, children }) => {
  // https://stripe.com/docs/elements/appearance-api
  const options = {
    clientSecret: paymentIntent?.clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        borderRadius: "4px",
      },
      rules: {
        ".Input": {
          boxShadow: "inset 0 1px 2px rgb(250, 250, 250)",
          border: "1px solid rgb(224 224 224)",
          padding: "12px",
        },
        ".Input--invalid": {
          border: "1px solid rgb(224 224 224)",
          boxShadow: "inset 0 1px 2px rgb(250, 250, 250)",
        },
        ".Label": {
          fontWeight: 500,
          marginBottom: "8px",
          fontSize: "14px",
          color: "#3d3d3d",
        },
        ".Error": {
          marginTop: "8px",
          color: "rgb(239, 80, 78)",
          fontSize: "16px",
        },
      },
    },
  }

  return (
    <div>
      {stripe && !error && (
        <Elements stripe={stripe} options={options}>
          {children}
        </Elements>
      )}

      {error && (
        <AlertMessage className="mt-2">
          <div style={{ color: "var(--color-ruby)" }}>{error}</div>
        </AlertMessage>
      )}
    </div>
  )
}

StripeWrapper.propTypes = {
  paymentIntent: object,
  error: string,
  stripe: object,
  children: node,
}

export default StripeWrapper
