import _ from "lodash"
import { array, bool } from "prop-types"
import { Menu, MenuButton, MenuList, MenuLink } from "@reach/menu-button"
import { date as formatDate } from "@churchcenter/datetime-fmt"
import { Heading, Subheading } from "@planningcenter/doxy-web"
import UpcomingSchedules from "./UpcomingSchedules"
import Blockout from "./Blockout"
import UpcomingGroupsEvent from "./UpcomingGroupsEvent"
import CalendarBookmark from "./CalendarBookmark"
import RegistrationsAttendee from "./RegistrationsAttendee"
import ServicesOptionsDropdown from "source/myChurchCenter/ServicesOptionsDropdown"
import BlankState from "source/groups/BlankState"
import { Icon } from "source/shared/components"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"

const MCC_UPCOMING_ACTIVITIES_FILTER_STORAGE_KEY = "MCCUpcomingActivitiesFilter"
const BLOCKOUT_TYPE = "Blockout"
const CALENDAR_BOOKMARK_TYPE = "CalendarBookmark"
const REGISTRATIONS_ATTENDEE_TYPE = "RegistrationsAttendee"
const UPCOMING_GROUPS_EVENT_TYPE = "UpcomingGroupsEvent"
const UPCOMING_SCHEDULES_TYPE = "UpcomingSchedules"

const UPCOMING_FILTER_OPTIONS = [
  { type: BLOCKOUT_TYPE, name: "Services blockouts" },
  { type: UPCOMING_SCHEDULES_TYPE, name: "Services teams" },
  { type: UPCOMING_GROUPS_EVENT_TYPE, name: "Group events" },
  { type: REGISTRATIONS_ATTENDEE_TYPE, name: "Registrations" },
  { type: CALENDAR_BOOKMARK_TYPE, name: "Bookmarks" },
]

function EmptyState() {
  return (
    <BlankState icon="churchCenter#calendar-events">
      <p>There are no upcoming events.</p>
    </BlankState>
  )
}

function activityDateName({
  starts_at,
  starts_at_time_zone,
  ends_at,
  ends_at_time_zone,
}) {
  const inCurrentYear =
    new Date().getFullYear() === new Date(ends_at).getFullYear()

  if (starts_at_time_zone === ends_at_time_zone) {
    return formatDate(starts_at, ends_at, {
      style: "long",
      year: !inCurrentYear,
      timeZone: starts_at_time_zone,
    })
  } else {
    return formatDate(starts_at, {
      style: "long",
      year: !inCurrentYear,
      timeZone: starts_at_time_zone,
    })
  }
}

const UPCOMING_COMPONENTS = {
  [UPCOMING_SCHEDULES_TYPE]: UpcomingSchedules,
  [BLOCKOUT_TYPE]: Blockout,
  [UPCOMING_GROUPS_EVENT_TYPE]: UpcomingGroupsEvent,
  [CALENDAR_BOOKMARK_TYPE]: CalendarBookmark,
  [REGISTRATIONS_ATTENDEE_TYPE]: RegistrationsAttendee,
}

const UpcomingFilterMenu = ({ filteredComponents, onSelect }) =>
  _.map(UPCOMING_FILTER_OPTIONS, ({ name, type }) => (
    <MenuLink
      as="div"
      key={type}
      className="mb-1"
      onClick={(e) => {
        onSelect(type)
        e.preventDefault()
      }}
    >
      <input
        id={type}
        type="checkbox"
        className="checkbox"
        checked={filteredComponents.includes(type)}
        onChange={() => onSelect(type)}
      />
      <label htmlFor={type} className="checkbox-label">
        <div>
          <span>{name}</span>
        </div>
      </label>
    </MenuLink>
  ))
function renderUpcomingActivity(activity) {
  const Component = UPCOMING_COMPONENTS[activity.type]

  return Component ? (
    <Component key={`${activity.type}-${activity.id}`} {...activity} />
  ) : null
}

UpcomingSection.propTypes = {
  activityDates: array,
  showOptionsDropdown: bool,
  hasServicesAbilities: bool,
}

export default function UpcomingSection({
  activityDates,
  showOptionsDropdown,
  hasServicesAbilities,
}) {
  const [filteredComponents, setFilteredComponents] = useLocalStorage(
    MCC_UPCOMING_ACTIVITIES_FILTER_STORAGE_KEY,
    _.map(UPCOMING_FILTER_OPTIONS, ({ type }) => type),
  )
  const isFiltering = filteredComponents.length < UPCOMING_FILTER_OPTIONS.length
  const handleFilterComponentClick = (type) =>
    setFilteredComponents(_.xor(filteredComponents, [type]))

  const filteredActivityDates = activityDates
    .map((activityDate) => ({
      ...activityDate,
      activities: activityDate.activities.filter(
        ({ type }) =>
          !filteredComponents.length || filteredComponents.includes(type),
      ),
    }))
    .filter((activityDate) => !!activityDate.activities.length)

  return (
    <div>
      <div className="d-f ai-c jc-sb mb-1">
        <Menu>
          <MenuButton className="btn text-btn">
            <div css={{ cursor: "pointer" }} className="d-f ai-b">
              <Heading
                level={2}
                text={`Schedule (${isFiltering ? "Filtered" : "All"})`}
              />
              <span className="fs-6 pl-4p dropdown-trigger__icon">
                <Icon symbol="general#down-chevron" />
              </span>
            </div>
          </MenuButton>
          <MenuList
            className="dropdown__menu"
            style={{ "--dropdown-menu--font-size": "1rem" }}
          >
            <fieldset>
              <UpcomingFilterMenu
                filteredComponents={filteredComponents}
                onSelect={handleFilterComponentClick}
              />
            </fieldset>
          </MenuList>

          {showOptionsDropdown && (
            <ServicesOptionsDropdown
              hasServicesAbilities={hasServicesAbilities}
            />
          )}
        </Menu>
      </div>

      {filteredActivityDates.length ? (
        <>
          {filteredActivityDates.map((activityDate) => (
            <div
              key={`${activityDate.starts_at}-${activityDate.ends_at}`}
              className="d-f fd-c mb-2"
            >
              <div className="mb-2">
                <Subheading level={3} text={activityDateName(activityDate)} />
              </div>
              {activityDate.activities.map(renderUpcomingActivity)}
            </div>
          ))}
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  )
}
