import { css } from "@emotion/react"
import { Fragment, useContext, useState, useEffect, useReducer } from "react"
import {
  any,
  arrayOf,
  bool,
  func,
  oneOf,
  shape,
  string,
  node,
} from "prop-types"
import { AlertMessage, Icon } from "source/shared/components"
import { VERIFIED_IN_MEMORY_STRATEGY } from "source/login/FindPersonConstants"
import { useFocus } from "source/shared/hooks/useFocus"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Heading } from "@planningcenter/doxy-web"
import { colors } from "source/shared/colors"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import LoginAlert from "./LoginAlert"
import { useDebounce } from "source/shared/hooks/useDebounce"

const deviceLabels = {
  email: "email address",
  phone: "mobile number",
}

const verifiedPersonShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  firstName: string.isRequired,
  avatarUrl: string.isRequired,
  hasEmail: bool.isRequired,
  hasPhone: bool.isRequired,
})

function buildVerifiedPersonShapeFromApiResponseData(responseData, deviceId) {
  const id = responseData.id
  const firstName = responseData.attributes.first_name
  const lastName = responseData.attributes.last_name
  const name = `${firstName} ${lastName}`
  const avatarUrl = responseData.attributes.avatar_url
  const hasEmail = responseData.attributes.email_present
  const hasPhone = responseData.attributes.phone_present

  return {
    id,
    firstName,
    name,
    avatarUrl,
    hasEmail,
    hasPhone,
    deviceId,
  }
}

const deviceShape = shape({
  id: string.isRequired,
  kind: oneOf(["email", "phone"]).isRequired,
  value: string.isRequired,
  isVerified: bool.isRequired,
  hasMatches: bool.isRequired,
  people: arrayOf(verifiedPersonShape),
})

function buildDeviceShapeFromVerifiedContactApiResponseData(response) {
  const responseData = response.data
  const id = responseData.id
  const kind = responseData.attributes.kind
  const meta = response.meta || { debug_code: "" }

  let value

  switch (kind) {
    case "phone":
      value = responseData.attributes.phone_number
      break
    case "email":
      value = responseData.attributes.email_address
      break
    default:
      throw `Unrecognized kind: ${kind}`
  }

  return {
    id,
    kind,
    value,
    meta,
    isDelivered: !!responseData.attributes.code_sent_at,
    isVerified: responseData.attributes.verified,
    hasMatches: responseData.attributes.matches_in_organization,
    people: [],
  }
}

const findVerifiedPersonInitialState = {
  devices: [],
  explicitNewPerson: false,
  skipPhone: false,
  person: null,
}

function findVerifiedPersonReducer(state, action) {
  switch (action.type) {
    case "appendDevice":
      return { ...state, devices: [...state.devices, action.payload.device] }
    case "replaceDevice":
      return {
        ...state,
        devices: state.devices.map((device) =>
          device.id === action.payload.device.id
            ? action.payload.device
            : device,
        ),
      }
    case "removeDevice":
      return {
        ...state,
        devices: state.devices.filter(
          (device) => device.id !== action.payload.device.id,
        ),
      }
    case "setPerson":
      return {
        ...state,
        person: action.payload.person,
        explicitNewPerson: false,
      }
    case "skipPhone":
      return { ...state, skipPhone: true }
    case "chooseNewPerson":
      return { ...state, explicitNewPerson: true }
    case "replaceState":
      return action.payload.state
    case "setError":
      return { ...state, error: action.payload.error }
    default:
      throw new Error("Unrecognized action in findVerifiedPersonReducer")
  }
}

function buildDerivedStateForPhoneAndEmailExperience(state) {
  const deviceAwaitingVerification = state.devices.find(
    (device) => device.isDelivered && !device.isVerified,
  )
  const deviceAwaitingPersonChoice = state.devices.find(
    (device) => device.isVerified && device.hasMatches,
  )
  const verifiedDevice = state.devices.find((device) => device.isVerified)

  const askForFirstDevice = state.devices.length === 0
  const askForSixDigitCode = deviceAwaitingVerification
  const askForPersonChoice =
    deviceAwaitingPersonChoice && !state.explicitNewPerson && !state.person
  const askForNewPerson =
    !state.person &&
    (state.explicitNewPerson ||
      (verifiedDevice && !solicitMoreContactData(state)))
  const askForSecondDevice = solicitMoreContactData(state)
  const otherKind =
    state.devices[0] && state.devices[0].kind === "email" ? "phone" : "email"
  const personAwaitingAuthentication = !askForSecondDevice && state.person

  let deviceAwaitingForcedCodeDelivery

  if (
    !(askForFirstDevice || askForSecondDevice) &&
    !state.devices.some((device) => device.isDelivered)
  ) {
    if (state.devices.length === 2) {
      deviceAwaitingForcedCodeDelivery = state.devices.find(
        (device) => device.kind === "phone",
      )
    } else {
      deviceAwaitingForcedCodeDelivery = state.devices[0]
    }
  }

  return {
    deviceAwaitingVerification,
    deviceAwaitingPersonChoice,
    deviceAwaitingForcedCodeDelivery,
    verifiedDevice,
    askForFirstDevice,
    askForSixDigitCode,
    askForPersonChoice,
    askForNewPerson,
    askForSecondDevice,
    otherKind,
    personAwaitingAuthentication,
  }
}

function buildDerivedStateForEmailOnlyExperience(state) {
  const derived = buildDerivedStateForPhoneAndEmailExperience(state)
  const askForNewPerson =
    !state.person && (state.explicitNewPerson || derived.verifiedDevice)
  const askForSecondDevice = false
  let deviceAwaitingForcedCodeDelivery = state.devices.find(
    (device) => !device.isDelivered,
  )
  const personAwaitingAuthentication = state.person

  return {
    ...derived,
    askForNewPerson,
    askForSecondDevice,
    deviceAwaitingForcedCodeDelivery,
    personAwaitingAuthentication,
  }
}

async function deliverCode(device) {
  await sessionApiClient.post(
    `/global/v2/verified_contacts/${device.id}/resend`,
  )
  return { ...device, isDelivered: true }
}

function solicitMoreContactData(state) {
  const emailRequirmentSatisfied = willHaveEmail(state)
  const phoneRequirementSatisfied = state.skipPhone || willHavePhone(state)
  return !(emailRequirmentSatisfied && phoneRequirementSatisfied)
}

function willAssociate(device) {
  return device.isVerified || !device.hasMatches
}

function willHaveEmail(state) {
  const willAssociateEmail = state.devices.find(
    (device) => device.kind === "email" && willAssociate(device),
  )
  return (state.person && state.person.hasEmail) || willAssociateEmail
}

function willHavePhone(state) {
  const willAssociatePhone = state.devices.find(
    (device) => device.kind === "phone" && willAssociate(device),
  )
  return (state.person && state.person.hasPhone) || willAssociatePhone
}

async function authenticatePerson(person) {
  const token = await sessionApiClient.post(
    `/global/v2/verified_contacts/${person.deviceId}/people/${
      person.id
    }/authenticate`,
  )

  // Return a result conforming to the BrowserSessionPerson/personShape
  //
  // We could take steps to recall the email_address/phone_number in state or fetch from
  // the API now that we have a ChurchCenterPersonToken... but YAGNI for our current purposes.
  return {
    data: {
      type: VERIFIED_IN_MEMORY_STRATEGY,
      id: person.id,
      attributes: {
        name: person.name,
        avatar_url: person.avatarUrl,
        email_address: null,
        phone_number: null,
        token,
      },
    },
  }
}

// Take advantage of our predictable pco-api errors responses shape
// {"errors":[{"status":"400","title":"Invalid phone number","code":400,"detail":"We cannot deliver to the phone number"}]}
export function communicateErrorAsHelpfulAsPossible(
  maybeJson,
  organization_email_address,
) {
  try {
    const { code, title, detail } = maybeJson.errors[0]

    if (code === 451) {
      return (
        <div className="fs-3 ta-c">
          <div className="mb-1">
            <Heading level={2} text="Uh oh!" />
          </div>
          <p className="mb-1 lh-1.5">
            Our records show that you are under the age of 13.
            <br />
            You must be 13 years of age or older to log in.
          </p>
          <p>
            If this is incorrect, please{" "}
            {organization_email_address ? (
              <a href={`mailto:${organization_email_address}`}>contact us.</a>
            ) : (
              <span>contact us.</span>
            )}
          </p>
        </div>
      )
    } else if (code === 400 && title.toLowerCase() === "invalid phone number") {
      return (
        <AlertMessage>
          We tried to deliver a text message to your phone to log you in, but we
          weren&rsquo;t able to. Using a phone number to log in is available for
          mobile phone numbers in the United States and Canada. Is your number
          including area code correct? If it is and we are unable to deliver you
          may want to <strong>try again using only an email address</strong>.
        </AlertMessage>
      )
    } else {
      return (
        <AlertMessage>
          Something unexpected happened. Please try again. (
          <em>
            {code} &ndash; {title} &ndash; {detail}
          </em>
          )
        </AlertMessage>
      )
    }
  } catch (_error) {
    return (
      <AlertMessage>
        Something unexpected happened. Please try again.
      </AlertMessage>
    )
  }
}

FindVerifiedPerson.propTypes = {
  autoSubmitDefaultValue: bool,
  onChange: func.isRequired,
  defaultValue: string,
  emailOnly: bool.isRequired,
}

export function FindVerifiedPerson({
  autoSubmitDefaultValue = false,
  onChange,
  defaultValue = "",
  emailOnly,
}) {
  let findVerifiedPersonDerivedState, firstDeviceProps

  if (emailOnly) {
    findVerifiedPersonDerivedState = buildDerivedStateForEmailOnlyExperience
    firstDeviceProps = {
      kind: "email",
      defaultValue: defaultValue.includes("@") ? defaultValue : "",
    }
  } else {
    findVerifiedPersonDerivedState = buildDerivedStateForPhoneAndEmailExperience
    firstDeviceProps = {
      defaultKind: defaultValue.includes("@") ? "email" : "phone",
      defaultValue,
    }
  }

  const {
    layout: { organization_contact_email },
  } = useContext(ServerRenderedProps)

  const [state, dispatch] = useReducer(
    findVerifiedPersonReducer,
    findVerifiedPersonInitialState,
  )
  const [autoSubmitFirstRenderOnly, setAutoSubmitFirstRenderOnly] = useState(
    autoSubmitDefaultValue && !!firstDeviceProps.defaultValue,
  )
  useEffect(() => {
    setAutoSubmitFirstRenderOnly(false)
  }, [])

  const {
    askForFirstDevice,
    askForNewPerson,
    askForPersonChoice,
    askForSecondDevice,
    askForSixDigitCode,
    deviceAwaitingPersonChoice,
    deviceAwaitingVerification,
    otherKind,
    verifiedDevice,
  } = findVerifiedPersonDerivedState(state)

  async function resolveOrContinue(action) {
    try {
      let nextState = findVerifiedPersonReducer(state, action)

      let { personAwaitingAuthentication, deviceAwaitingForcedCodeDelivery } =
        findVerifiedPersonDerivedState(nextState)

      if (personAwaitingAuthentication) {
        const person = await authenticatePerson(personAwaitingAuthentication)
        return onChange(person)
      }

      if (deviceAwaitingForcedCodeDelivery) {
        const device = await deliverCode(deviceAwaitingForcedCodeDelivery)
        nextState = findVerifiedPersonReducer(nextState, {
          type: "replaceDevice",
          payload: { device },
        })
      }

      dispatch({ type: "replaceState", payload: { state: nextState } })
    } catch (error) {
      const message = communicateErrorAsHelpfulAsPossible(
        error,
        organization_contact_email,
      )
      dispatch({ type: "setError", payload: { error: message } })
    }
  }

  function appendDevice(device) {
    resolveOrContinue({ type: "appendDevice", payload: { device } })
  }

  function confirmDevice(device) {
    resolveOrContinue({ type: "replaceDevice", payload: { device } })
  }

  function choosePerson(person) {
    resolveOrContinue({ type: "setPerson", payload: { person } })
  }

  function skipPhone() {
    resolveOrContinue({ type: "skipPhone" })
  }

  function removeDevice(device) {
    resolveOrContinue({ type: "removeDevice", payload: { device } })
  }

  function chooseNewPerson() {
    resolveOrContinue({ type: "chooseNewPerson" })
  }

  if (state.error) {
    return <ErrorScreen error={state.error} />
  } else if (askForFirstDevice) {
    return (
      <DeviceForm
        key="firstDevice"
        onSuccess={appendDevice}
        {...firstDeviceProps}
        autoSubmit={autoSubmitFirstRenderOnly}
      />
    )
  } else if (askForSixDigitCode) {
    return (
      <SixDigitForm
        device={deviceAwaitingVerification}
        onEditDevice={removeDevice}
        onSuccess={confirmDevice}
      />
    )
  } else if (askForPersonChoice) {
    return (
      <ChoosePerson
        people={deviceAwaitingPersonChoice.people}
        kind={deviceAwaitingPersonChoice.kind}
        onChoosePerson={choosePerson}
        onChooseNewPerson={chooseNewPerson}
      />
    )
  } else if (askForNewPerson) {
    return <NewPersonForm device={verifiedDevice} onSuccess={choosePerson} />
  } else if (askForSecondDevice) {
    return (
      <DeviceForm
        key="secondDevice"
        kind={otherKind}
        onSkip={state.devices[0].kind === "email" ? skipPhone : null}
        onSuccess={appendDevice}
        secondDevice
      />
    )
  } else {
    throw "We thought this was an impossible state!"
  }
}

const trackEmailSuggestionsClick = (original_address, suggested_address) => {
  sessionApiClient.post("/accounts/v2/track_email_replacement_selected_count", {
    data: {
      attributes: {
        origin: "ccw_login",
        original_address,
        suggested_address,
      },
    },
  })
}

DeviceForm.propTypes = {
  autoSubmit: bool,
  defaultKind: string,
  defaultValue: string,
  kind: string,
  onSkip: func,
  onSuccess: func.isRequired,
  secondDevice: bool,
}
function DeviceForm({
  autoSubmit = false,
  defaultKind,
  defaultValue = "",
  kind: controlledKind,
  onSuccess,
  onSkip,
  secondDevice,
}) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [kind, setKind] = useState(controlledKind || defaultKind)
  const [value, setValue] = useState(defaultValue)
  const [focusRef, focus] = useFocus()
  const [suggestedReplacement, setSuggestedReplacement] = useState(undefined)
  const [showSuggestion, setShowSuggestion] = useState(false)
  const debouncedValue = useDebounce(value, 300)

  useEffect(() => {
    if (autoSubmit && value) {
      onSubmit()
    }
  }, [])
  useEffect(focus)
  const {
    layout: { organization_contact_email },
  } = useContext(ServerRenderedProps)

  const otherKind = kind === "email" ? "phone" : "email"

  async function onSubmit() {
    if (loading) return

    setLoading(true)
    setError(false)

    if (value.replace(/ /g, "").length === 0) {
      const errorMessage =
        kind === "email" ? "an email address" : "a phone number"
      setError(`Please enter ${errorMessage} and try again.`)
      setLoading(false)
      return
    }

    const kindAsApiAttributeKey =
      kind === "email" ? "email_address" : "phone_number"

    try {
      const response = await sessionApiClient.post(
        "/global/v2/verified_contacts",
        {
          data: {
            type: "VerifiedContact",
            attributes: { [kindAsApiAttributeKey]: value },
          },
        },
      )
      onSuccess(buildDeviceShapeFromVerifiedContactApiResponseData(response))
    } catch (error) {
      const message = communicateErrorAsHelpfulAsPossible(
        error,
        organization_contact_email,
      )
      setError(message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!debouncedValue || debouncedValue === suggestedReplacement) {
      setShowSuggestion(false)
      return
    }

    sessionApiClient
      .post("/accounts/v2/suggest_email_replacement", {
        data: { attributes: { email: debouncedValue } },
      })
      .then(({ data }) => {
        setSuggestedReplacement(data.attributes.suggested_replacement)
        setShowSuggestion(!!data.attributes.suggested_replacement)
      })
      .catch(() => setShowSuggestion(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const deviceOptimizedInputProps =
    kind === "email"
      ? {
          inputMode: "email",
          onChange(event) {
            setValue(event.target.value.replace(/\s/g, ""))
            setShowSuggestion(false)
          },
          pattern: "^\\S+@\\S+\\.\\S+$",
          type: "email",
          placeholder: "name@email.com",
          ...(showSuggestion && {
            style: {
              boxShadow: "none",
              outline: "none",
              borderColor: "var(--color-citrine)",
            },
          }),
        }
      : {
          inputMode: "number",
          onChange(event) {
            setValue(event.target.value.replace(/[^\d- .]/g, ""))
          },
          type: "tel",
          placeholder: "888-555-1212",
        }

  return (
    <>
      <LoginAlert />
      <form
        onSubmit={(event) => {
          event.preventDefault()
          onSubmit()
        }}
        className="login-form--narrow"
      >
        <label htmlFor="device_value" className="fs-3">
          <span className="label-text mb-1 ta-c">
            {secondDevice ? (
              <span>
                <strong>
                  {kind === "email"
                    ? "To send you notices about your account,"
                    : "Add a mobile number to your profile"}
                </strong>
                {kind === "email"
                  ? " we also need your email address."
                  : " and next time you can log in with email or phone."}
              </span>
            ) : (
              <span>
                <strong>
                  To get started, enter your {deviceLabels[kind]}.
                </strong>
                <br />
                We&rsquo;ll send you a code you can use to log in or create an
                account.
              </span>
            )}
          </span>
          <input
            id="device_value"
            ref={focusRef}
            value={value}
            autoComplete="off"
            disabled={loading}
            className="ta-c"
            {...deviceOptimizedInputProps}
          />
        </label>
        {showSuggestion && (
          <div
            className="fs-4"
            style={{
              color: "var(--warning-alert--text)",
              marginTop: "-6px",
            }}
          >
            Did you mean <strong>{suggestedReplacement}</strong>?{" "}
            <button
              className="btn naked-btn primary-btn fs-5 h-2 td-u:h"
              onClick={() => {
                trackEmailSuggestionsClick(debouncedValue, suggestedReplacement)
                setValue(suggestedReplacement)
                setShowSuggestion(false)
              }}
              style={{
                padding: 0,
                borderRadius: 0,
                color: "var(--color-tint2)",
              }}
            >
              Replace
            </button>
          </div>
        )}

        {error && <AlertMessage className="ta-c mt-1">{error}</AlertMessage>}
        <div className="ta-c">
          <div
            className="sm-alert-info-alert alert c-tint2 py-1 px-4 fs-13 mt-1"
            css={{ lineHeight: 1.5 }}
          >
            By continuing, you agree to Planning Center&apos;s{" "}
            <a
              href="https://planning.center/terms/"
              target="_blank"
              rel="noopener noreferrer"
              className="c-tint2"
              style={{
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "#e0e0e0",
              }}
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://planning.center/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="c-tint2"
              style={{
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "#e0e0e0",
              }}
            >
              Privacy Policy
            </a>
            .
          </div>
          <button className="btn mb-3 mt-3" type="submit" disabled={loading}>
            Next
          </button>
          {defaultKind && (
            <div>
              <button
                disabled={loading}
                className="c-tint3 text-btn"
                onClick={(event) => {
                  event.preventDefault()
                  setValue("")
                  setError(false)
                  setKind(otherKind)
                }}
              >
                Use {deviceLabels[otherKind]} instead
              </button>
            </div>
          )}
        </div>
        {onSkip && (
          <div className="ta-c mt-3">
            <button
              disabled={loading}
              className="c-tint3 text-btn"
              onClick={(event) => {
                event.preventDefault()
                onSkip()
              }}
            >
              Skip
              <span
                css={{ position: "relative", top: "2px", marginLeft: "8px" }}
              >
                <Icon symbol="general#right-arrow" />
              </span>
            </button>
          </div>
        )}
      </form>
    </>
  )
}

SixDigitForm.propTypes = {
  onEditDevice: func.isRequired,
  onSuccess: func.isRequired,
  device: shape({
    id: string.isRequired,
    hasMatches: bool.isRequired,
  }).isRequired,
}
function SixDigitForm({ device, onEditDevice, onSuccess }) {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState(device.meta.debug_code)
  const [focusRef, focus] = useFocus()
  useEffect(focus)

  async function onSubmit(event) {
    event.preventDefault()
    if (loading) return

    setError(false)
    setLoading(true)

    try {
      await submitCode()
    } catch (error) {
      setCode("")
      setLoading(false)
      setError(error)
      return
    }
    const people = await loadPeople()
    onSuccess({
      ...device,
      isVerified: true,
      people,
    })
  }

  async function submitCode() {
    try {
      const codeDigits = code
        .split("")
        .filter((char) => char.match(/\d/))
        .join("")
      await sessionApiClient.post(
        `/global/v2/verified_contacts/${device.id}/verify`,
        {
          data: {
            attributes: {
              code: codeDigits,
            },
          },
        },
      )
    } catch (_error) {
      throw "Could not verify code. Please try again."
    }
  }

  async function loadPeople() {
    if (!device.hasMatches) return []
    const response = await sessionApiClient.get(
      `/global/v2/verified_contacts/${device.id}/people`,
    )
    return response.data.map((record) =>
      buildVerifiedPersonShapeFromApiResponseData(record, device.id),
    )
  }

  const verb = device.kind === "email" ? "email" : "text"
  const verbed = device.kind === "email" ? "emailed" : "texted"

  async function resendCode(event) {
    event.preventDefault()
    if (loading) return
    setLoading(true)
    try {
      await sessionApiClient.post(
        `/global/v2/verified_contacts/${device.id}/resend`,
      )
      alert(`A code has been ${verbed} to ${device.value}.`)
      setLoading(false)
    } catch (_error) {
      alert(`Could not ${verb} code to ${device.value}. Please try again.`)
      setLoading(false)
    }
  }

  function editDevice(event) {
    event.preventDefault()
    onEditDevice(device)
  }

  return (
    <form onSubmit={onSubmit} className="login-form--narrow">
      <label htmlFor="six_digit_code">
        <span className="label-text fs-3 mb-1 ta-c">
          Please enter the six-digit code sent to {device.value}:
        </span>
        <input
          autoComplete="off"
          disabled={loading}
          id="six_digit_code"
          inputMode="number"
          onChange={(event) => setCode(event.target.value.replace(/\D/g, ""))}
          pattern="[0-9]*"
          ref={focusRef}
          required
          type="text"
          placeholder="000000"
          value={code}
          className="ta-c"
        />
      </label>
      {error && <AlertMessage className="mt-1">{error}</AlertMessage>}
      <div className="ta-c my-3">
        <button className="btn" type="submit" disabled={loading}>
          Next
        </button>
      </div>
      <div className="d-f jc-sb">
        <button
          className="c-tint3 text-btn"
          disabled={loading}
          onClick={editDevice}
        >
          Edit {deviceLabels[device.kind]}
        </button>
        <button
          className="c-tint3 text-btn"
          disabled={loading}
          onClick={resendCode}
        >
          Resend code
        </button>
      </div>
    </form>
  )
}

NewPersonForm.propTypes = {
  device: deviceShape.isRequired,
  onSuccess: func.isRequired,
}
function NewPersonForm({ device, onSuccess }) {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [focusRef] = useFocus(true)

  async function onSubmit(captchaCode) {
    if (loading) return

    setError(false)
    setLoading(true)

    const person = await createNewProfile(captchaCode)
    if (person) onSuccess(person)
    else {
      setError("Could not create new profile. Please try again.")
      setLoading(false)
    }
  }

  function onError() {
    setError("Could not create new profile. Please try again.")
    setLoading(false)
  }

  async function createNewProfile(captchaCode) {
    try {
      const response = await sessionApiClient.post(
        `/global/v2/verified_contacts/${device.id}/people`,
        {
          data: {
            attributes: {
              first_name,
              last_name,
              "g-recaptcha-response": captchaCode,
            },
          },
        },
      )
      return buildVerifiedPersonShapeFromApiResponseData(
        response.data,
        device.id,
      )
    } catch (_error) {
      return null
    }
  }

  return (
    <CaptchaForm onSuccess={onSubmit} onError={onError}>
      <div className="ta-c mb-3">
        <strong>Almost there!</strong> Enter your first and last name to
        complete your profile.
      </div>
      <div className="d-f f_1">
        <div className="pr-1">
          <label className="screen-reader-text" htmlFor="new_person_first_name">
            First name
          </label>
          <input
            id="new_person_first_name"
            type="text"
            ref={focusRef}
            autoComplete="off"
            placeholder="First name"
            value={first_name}
            onChange={(event) => setFirstName(event.target.value)}
            disabled={loading}
            required
          />
        </div>
        <div className="pl-1">
          <label className="screen-reader-text" htmlFor="new_person_first_name">
            Last name
          </label>
          <input
            id="new_person_last_name"
            type="text"
            autoComplete="off"
            placeholder="Last name"
            value={last_name}
            onChange={(event) => setLastName(event.target.value)}
            disabled={loading}
            required
          />
        </div>
      </div>
      {error && <AlertMessage className="ta-c mt-1">{error}</AlertMessage>}
      <div className="ta-c my-3">
        <button className="btn" type="submit" disabled={loading}>
          Next
        </button>
      </div>
    </CaptchaForm>
  )
}

CaptchaForm.propTypes = {
  onSuccess: func.isRequired,
  onError: func.isRequired,
  children: node.isRequired,
}

function CaptchaForm(props) {
  const { children, onSuccess } = props
  const { features } = useContext(ServerRenderedProps)

  function handleSubmit(e) {
    e.preventDefault()
    onSuccess()
  }

  if (features.use_profile_create_captcha) {
    return <CaptchaEnabledForm {...props} />
  } else {
    return <form onSubmit={handleSubmit}>{children}</form>
  }
}

CaptchaEnabledForm.propTypes = CaptchaForm.propTypes

function CaptchaEnabledForm({ children, onSuccess, onError }) {
  const { recaptcha } = useContext(ServerRenderedProps)
  const [recaptchaInstance, setRecaptchaInstance] = useState(null)
  const [recaptchaCode, setRecaptchaCode] = useState(null)
  const [recaptchaError, setRecaptchaError] = useState(null)

  function verifyCaptcha(e) {
    e.preventDefault()

    let instance = recaptchaInstance

    if (recaptchaCode) {
      instance = setupCaptcha()
    }

    window.grecaptcha.execute(instance)
  }

  function setupCaptcha() {
    const instance = window.grecaptcha.render({
      sitekey: recaptcha.site_key,
      size: "invisible",
      callback: setRecaptchaCode,
      "expired-callback": setRecaptchaError,
      "error-callback": setRecaptchaError,
    })

    setRecaptchaCode(null)
    setRecaptchaInstance(instance)
    return instance
  }

  useEffect(() => {
    setupCaptcha()
  }, [])

  useEffect(() => {
    recaptchaCode && onSuccess(recaptchaCode)
  }, [recaptchaCode])

  useEffect(() => {
    recaptchaError && onError()
  }, [recaptchaError])

  return (
    <Fragment>
      <form onSubmit={verifyCaptcha}>
        {children}

        <div className="ta-c">
          <div
            className="c-tint2 py-1 px-4 fs-13 mt-1"
            style={{
              backgroundColor: "#fafafa",
              borderRadius: 4,
            }}
          >
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="c-tint2"
              style={{
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "#e0e0e0",
              }}
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="c-tint2"
              style={{
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "#e0e0e0",
              }}
            >
              Terms of Service
            </a>{" "}
            apply.
          </div>
        </div>
      </form>
    </Fragment>
  )
}

ChoosePerson.propTypes = {
  kind: oneOf(["email", "phone"]).isRequired,
  onChooseNewPerson: func.isRequired,
  onChoosePerson: func.isRequired,
  people: arrayOf(verifiedPersonShape).isRequired,
}
function ChoosePerson({ onChooseNewPerson, onChoosePerson, people, kind }) {
  const [loading, setLoading] = useState(false)

  function choosePerson(person) {
    setLoading(true)
    onChoosePerson(person)
  }

  return (
    <Fragment>
      {people.length === 1 ? (
        <ConfirmSinglePerson
          person={people[0]}
          choosePerson={choosePerson}
          loading={loading}
        />
      ) : (
        <ChooseFromManyPeople
          people={people}
          choosePerson={choosePerson}
          kind={kind}
          loading={loading}
        />
      )}
      <div className="ta-c mt-3">
        <button
          disabled={loading}
          className="c-tint3 text-btn"
          key="new-person"
          onClick={onChooseNewPerson}
        >
          Not you?
          <span css={{ position: "relative", top: "2px", marginLeft: "8px" }}>
            <Icon symbol="general#right-arrow" aria-hidden />
          </span>
        </button>
      </div>
    </Fragment>
  )
}

ConfirmSinglePerson.propTypes = {
  choosePerson: func.isRequired,
  loading: bool.isRequired,
  person: verifiedPersonShape.isRequired,
}
function ConfirmSinglePerson({ person, choosePerson, loading }) {
  return (
    <div className="ta-c">
      <div className="mb-1">
        <Heading level={2} text={`Hello, ${person.firstName}!`} />
      </div>
      <div>Let&rsquo;s get you logged in.</div>
      <button
        disabled={loading}
        onClick={() => choosePerson(person)}
        className="btn mt-3"
      >
        Log in as {person.firstName}
      </button>
    </div>
  )
}

ChooseFromManyPeople.propTypes = {
  choosePerson: func.isRequired,
  kind: oneOf(["email", "phone"]).isRequired,
  loading: bool.isRequired,
  people: arrayOf(verifiedPersonShape).isRequired,
}
function ChooseFromManyPeople({ people, choosePerson, kind, loading }) {
  const styles = {
    image: {
      borderRadius: "56px",
    },
    button: {
      backgroundColor: colors.tint10,
      border: `1px solid ${colors.tint6}`,
      borderRadius: "4px",
      color: colors.tint0,
      cursor: "pointer",
      fontSize: "16px",
      marginBottom: "16px",
      padding: "16px",
      textAlign: "center",
      transition: "box-shadow 0.2s",
      width: "calc(50% - 8px)",
      "&:nth-child(odd)": { marginRight: "8px" },
      "&:nth-child(even)": { marginLeft: "8px" },
      "&:hover": {
        boxShadow: "inset 0 0 0 2px rgba(220, 220, 220, 0.2)",
      },
    },
  }

  return (
    <Fragment>
      <div className="ta-c mb-3">
        <div>
          <strong>
            We found {people.length} profiles that match that{" "}
            {deviceLabels[kind]}.
          </strong>
        </div>
        <div>Log in as:</div>
      </div>
      <div>
        {people.map((person) => (
          <button
            disabled={loading}
            key={person.id}
            onClick={() => choosePerson(person)}
            css={styles.button}
          >
            <img
              src={`${person.avatarUrl}?g=200x200%23`}
              className="w-7 h-7"
              css={styles.image}
              alt={person.name}
            />
            <div css={{ paddingTop: "10px" }}>{person.name}</div>
          </button>
        ))}
      </div>
    </Fragment>
  )
}

ErrorScreen.propTypes = {
  error: any.isRequired,
}
function ErrorScreen({ error }) {
  return (
    <div className="mx-8 my-4 ta-c">
      {error}
      <button
        className="secondary-btn btn mb-3 mt-3"
        onClick={() => window.location.reload()}
      >
        Start over
      </button>
    </div>
  )
}
