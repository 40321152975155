import {
  useQuery,
  splitQueryParams,
  queryFn,
} from "source/registrations/utils/queryCache"

export const useOrganization = (options = {}) => {
  const path = "/registrations/v2"
  const { params, queryOptions } = splitQueryParams(options)

  return useQuery({ queryFn, queryKey: [path, params], ...queryOptions })
}

export const useOrganizationFlag = (name, options) => {
  const { data } = useOrganization(options)
  return data?.productFeatures[name] || false
}

export const useOrganizationSetting = (name, options) => {
  const { data } = useOrganization(options)
  return data?.settings[name] || null
}

export const useOrganizationProxyPaths = (options) => {
  const { data } = useOrganization(options)

  return (
    data?.proxyTransition
      .filter(({ redirectToProxy }) => redirectToProxy)
      .map(({ path }) => path.replace(/\/registrations/, "")) || null
  )
}
