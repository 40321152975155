import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { css } from "@emotion/react"
import PropTypes from "prop-types"
import moment from "moment"
import { useDebouncedCallback } from "source/shared/hooks/useDebounce"
import { useInterval } from "source/shared/hooks/useInterval"
import { isChurchCenterApp } from "source/Layout"
import { WebBootContext } from "source/publishing/WebBoot"
import { playAppAudio } from "source/publishing/sermons/Episode/LibraryEpisode"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"

AudioPlayer.propTypes = {
  audioDownloadLink: PropTypes.string,
  autoplay: PropTypes.bool,
  episode: PropTypes.object,
  personEpisodeProgress: PropTypes.object,
  setAudioDownloadLink: PropTypes.func,
}

export default function AudioPlayer({
  audioDownloadLink,
  autoplay,
  episode,
  personEpisodeProgress,
  setAudioDownloadLink,
}) {
  const EXPIRED_S3_LINK_CODE = 2
  const audioRef = useRef(new Audio())
  const { currentPerson } = useContext(WebBootContext)
  const progressSeconds =
    personEpisodeProgress?.attributes.progress_seconds || 0
  const hasPlayableAudio =
    episode.attributes.sermon_audio.attributes.source === "remote" ||
    episode.attributes.sermon_audio.attributes.source === "hosted"

  const [duration, setDuration] = useState()
  const [currentTime, setCurrentTime] = useState(progressSeconds)
  const [loading, setLoading] = useState(false)
  const [seeking, setSeeking] = useState(false)
  const [hardLimitError, setHardLimitError] = useState(false)
  const [episodeDisabledError, setEpisodeDisabledError] = useState(false)

  const { library_art_url: art } = episode.attributes
  const episodeUrl = episode.links.self
  const POST_PROGRESS_INTERVAL_SECONDS = 15000
  const [postProgressDelay, setPostProgressDelay] = useState(null)

  const shouldShowSpinner = loading || (!audioRef?.current?.paused && seeking)
  const trackProgress = useCallback((time) => {
    if (currentPerson.id === "anonymous") return

    sessionApiClient.post(`${episodeUrl}/record_progress`, {
      data: {
        type: "RecordProgressAction",
        attributes: {
          progress_seconds: Math.floor(time),
        },
      },
    })
  }, [])

  const debouncedTrackProgress = useDebouncedCallback(trackProgress, 200)

  function formatDuration(duration) {
    return duration
      ? moment
          .duration(duration, "seconds")
          .format("h:mm:ss", { stopTrim: "m" })
      : ""
  }

  function formatDatetimeDuration(duration) {
    return duration
      ? moment.duration(duration, "seconds").format("[PT]h[H]m[M]s[S]", {
          trim: false,
        })
      : "PT0H0M0S"
  }
  function requestPlayableAudio() {
    setLoading(true)
    sessionApiClient
      .post(`/publishing/v2/episodes/${episode.id}/audio_download`, {
        data: { attributes: {} },
      })
      .then(
        ({
          data: {
            attributes: { url },
          },
        }) => {
          setAudioDownloadLink(url)
          setLoading(false)
        },
      )
      .catch(({ errors: [error] }) => {
        setLoading(false)
        switch (error?.code) {
          case "hard_limit_error":
            setHardLimitError(true)
            break
          case "disabled_episode_error":
            setEpisodeDisabledError(true)
            break
          default:
            alert("Something went wrong. Please, try again.")
            break
        }
      })
  }

  function requestPlayableAudioOrTogglePlay() {
    if (hasPlayableAudio && !audioDownloadLink && !isChurchCenterApp()) {
      requestPlayableAudio()
    } else {
      togglePlay()
    }
  }

  function togglePlay() {
    if (audioRef.current.paused) {
      audioRef.current.play()
      setPostProgressDelay(POST_PROGRESS_INTERVAL_SECONDS)
    } else {
      audioRef.current.pause()
      setPostProgressDelay(null)
    }
  }

  function onTimeUpdate() {
    if (!audioRef.current.paused) {
      setCurrentTime(audioRef.current.currentTime)
    }
  }

  function onLoadedData() {
    audioRef.current.currentTime = currentTime
    setDuration(audioRef.current.duration)
  }

  function onEnded() {
    audioRef.current.currentTime = 0
    setCurrentTime(0)
    debouncedTrackProgress(0)
    setPostProgressDelay(null)
  }

  function onError(e) {
    if (e.target.error.code === EXPIRED_S3_LINK_CODE) {
      requestPlayableAudio()
    } else {
      throw e.target.error
    }
  }

  function onSeeking() {
    setSeeking(true)
  }

  function onSeeked() {
    setSeeking(false)
  }

  function onProgressInputChange({ target: { value } }) {
    audioRef.current.currentTime = value
    setCurrentTime(value)

    debouncedTrackProgress(value)

    if (audioRef.current.paused) {
      requestPlayableAudioOrTogglePlay()
    }
  }

  function onKeyDown(e) {
    const { key } = e

    switch (key) {
      case "j":
      case "ArrowLeft":
        audioRef.current.currentTime = Math.max(currentTime - 1, 0)
        break
      case "l":
      case "ArrowRight":
        audioRef.current.currentTime = Math.min(currentTime + 1, duration)
        break
      case "k":
      case " ":
        e.preventDefault()
        requestPlayableAudioOrTogglePlay()
        break
      default:
        console.log({ key })
    }
  }

  function onPlay() {
    debouncedTrackProgress(currentTime)

    if (!postProgressDelay) {
      setPostProgressDelay(POST_PROGRESS_INTERVAL_SECONDS)
    }
  }

  function onPause() {
    debouncedTrackProgress(currentTime)
  }

  function skipBack() {
    audioRef.current.currentTime = Math.max(0, currentTime - 15)
    setCurrentTime(audioRef.current.currentTime)
  }

  function skipForward() {
    audioRef.current.currentTime = Math.min(duration, currentTime + 30)
    setCurrentTime(audioRef.current.currentTime)
  }

  useInterval(() => {
    debouncedTrackProgress(currentTime)
  }, postProgressDelay)

  useEffect(() => {
    if (hasPlayableAudio && !audioDownloadLink) {
      requestPlayableAudioOrTogglePlay()
      return
    }

    if (audioDownloadLink && autoplay && audioRef?.current) {
      audioRef.current.play()
    }
  }, [audioDownloadLink])

  return (
    <div className="p-r">
      {/* eslint-disable jsx-a11y/media-has-caption */}
      {!isChurchCenterApp() && (
        <audio
          ref={audioRef}
          playsInline
          src={audioDownloadLink}
          preload="auto"
          {...{
            onEnded,
            onError,
            onLoadedData,
            onPause,
            onPlay,
            onSeeked,
            onSeeking,
            onTimeUpdate,
          }}
        />
      )}
      {/* eslint-enable jsx-a11y/media-has-caption */}

      <div className="p-r">
        <img className="d-b" alt="Episode art" css={styles.image} src={art} />
        <div className="p-a l-0 r-0 b-0 t-0 d-f jc-c ai-c">
          {shouldShowSpinner && <div css={[styles.spinner, styles.seeking]} />}
        </div>
      </div>

      {isChurchCenterApp() && (
        <div
          className="p-a l-0 r-0 b-0 d-f jc-sb ai-c fd-r p-2"
          css={styles.controlsWrapper}
        >
          <button
            css={[styles.playPause, styles.play]}
            onClick={() => playAppAudio(episode)}
          >
            {"Play"}
          </button>
        </div>
      )}

      {!isChurchCenterApp() && (
        <div
          className="p-a l-0 r-0 b-0 d-f jc-sb ai-c fd-r p-2"
          css={styles.controlsWrapper}
        >
          <div css={styles.controlsCell}>
            <button
              css={[styles.playPause, styles.skipBack]}
              onClick={skipBack}
            >
              Skip back 15 seconds
            </button>
          </div>
          <div css={styles.controlsCell}>
            <button
              css={[
                styles.playPause,
                audioRef.current.paused ? styles.play : styles.pause,
              ]}
              onClick={requestPlayableAudioOrTogglePlay}
              onKeyDown={onKeyDown}
            >
              {audioRef.current.paused ? "Play" : "Pause"}
            </button>
          </div>
          <div css={styles.controlsCell}>
            <button
              css={[styles.playPause, styles.skipForward]}
              onClick={skipForward}
            >
              Skip forward 30 seconds
            </button>
          </div>
          <div css={styles.progressWrapper}>
            <progress
              css={styles.progressBar}
              max={duration}
              value={parseFloat(currentTime).toFixed(4)}
            />
            <input
              type="range"
              css={styles.progressInput}
              disabled={isChurchCenterApp()}
              onChange={onProgressInputChange}
              onKeyDown={onKeyDown}
              value={parseFloat(currentTime).toFixed(4)}
              min={0}
              max={duration}
              step="any"
            />
            <time
              css={[
                styles.time,
                { position: "absolute", bottom: -24, left: 0 },
              ]}
              dateTime={formatDatetimeDuration(currentTime)}
              className="fs-4"
            >
              {currentTime ? formatDuration(currentTime) : "0:00"}
            </time>
            <time
              css={[
                styles.time,
                { position: "absolute", bottom: -24, right: 0 },
              ]}
              dateTime={formatDatetimeDuration(duration)}
              className="fs-4"
            >
              {formatDuration(duration)}
            </time>
          </div>
        </div>
      )}
      <HardLimitModal
        hardLimitError={hardLimitError}
        setHardLimitError={setHardLimitError}
      />
      <EpisodeDisabledModal
        episodeDisabledError={episodeDisabledError}
        setEpisodeDisabledError={setEpisodeDisabledError}
      />
    </div>
  )
}

HardLimitModal.propTypes = {
  hardLimitError: PropTypes.bool.isRequired,
  setHardLimitError: PropTypes.func.isRequired,
}
function HardLimitModal({ hardLimitError, setHardLimitError }) {
  const {
    layout: { organization_contact_email },
  } = useContext(ServerRenderedProps)
  const cancelRef = useRef()
  const close = () => setHardLimitError(false)

  if (!hardLimitError) return null

  return (
    <AlertDialog leastDestructiveRef={cancelRef} onDismiss={close}>
      <AlertDialogLabel>Error: Download limit reached</AlertDialogLabel>
      <AlertDialogDescription>
        Your church has reached it’s limit of audio downloads, please contact
        your&nbsp;
        {organization_contact_email ? (
          <a href={`mailto:${organization_contact_email}`}>church</a>
        ) : (
          "church"
        )}
        &nbsp;for help.
        <div className="d-f jc-fe mt-3">
          <button onClick={close}>Close</button>
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}

EpisodeDisabledModal.propTypes = {
  episodeDisabledError: PropTypes.bool.isRequired,
  setEpisodeDisabledError: PropTypes.func.isRequired,
}
function EpisodeDisabledModal({
  episodeDisabledError,
  setEpisodeDisabledError,
}) {
  const cancelRef = useRef()
  const close = () => setEpisodeDisabledError(false)

  if (!episodeDisabledError) return null

  return (
    <AlertDialog leastDestructiveRef={cancelRef} onDismiss={close}>
      <AlertDialogLabel>
        Error: Episode audio download disabled
      </AlertDialogLabel>
      <AlertDialogDescription>
        Audio download for this episode has been temporarily disabled. Please
        check back later.
        <div className="d-f jc-fe mt-3">
          <button onClick={close}>Close</button>
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}

const thumb = css`
  -webkit-appearance: none;
  opacity: 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  cursor: pointer;
  background-color: var(--color-brand);
  box-shadow: none;
  transition:
    opacity 0.2s ease-in,
    transform 0.2s ease-in;
  border: none;
  transform: scale(0);
  transform-origin: center center;
`

const styles = {
  image: css`
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  `,
  controlsWrapper: css`
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  `,
  controlsCell: css`
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  playPause: css`
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    text-indent: 200%;
    white-space: nowrap;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      width: 32px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: var(--color-static-gray100);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  play: css`
    &:after {
      width: 48px;
      height: 48px;
      mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 0h24v24h-24z' fill='none'/%3E%3Cpath d='m8 5v14l11-7z'/%3E%3C/svg%3E");
    }
  `,
  pause: css`
    &:after {
      width: 48px;
      height: 48px;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 19h4V5H6v14zm8-14v14h4V5h-4z'/%3E%3C/svg%3E");
    }
  `,
  skipBack: css`
    background-color: transparent;
    &:after {
      background-color: var(--color-static-gray100);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z'/%3E%3C/svg%3E");
    }
  `,
  skipForward: css`
    background-color: transparent;
    &:after {
      background-color: var(--color-static-gray100);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3E%3Crect fill='none'/%3E%3Cpath d='M18,13c0,3.31-2.69,6-6,6s-6-2.69-6-6s2.69-6,6-6v4l5-5l-5-5v4c-4.42,0-8,3.58-8,8c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8 H18z'/%3E%3C/svg%3E");
    }
  `,
  progressWrapper: css`
    margin: 0 16px;
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  progressInput: css`
    -webkit-appearance: none;
    height: 8px;
    margin: 0;
    background: transparent;
    position: absolute;
    top: 50%;
    left: -8px;
    right: -8px;
    transform: translateY(-50%);
    z-index: 100;
    cursor: pointer;
    border-radius: 4px;

    &::-webkit-slider-thumb {
      ${thumb}
    }

    &::-moz-range-thumb {
      ${thumb}
    }

    &::-moz-range-track {
      background: transparent;
    }
    &::-ms-range-track {
      background: transparent;
    }

    &:hover {
      &::-webkit-slider-thumb {
        opacity: 1;
        transform: scale(1);
      }
      &::-moz-range-thumb {
        opacity: 1;
        transform: scale(1);
      }
    }
  `,
  progressBar: css`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: var(--color-static-gray100);
    overflow: hidden;

    &::-webkit-progress-bar {
      background-color: var(--color-static-gray100);
      border-radius: 4px;
    }

    &::-moz-progress-bar {
      background-color: var(--color-brand);
      border-radius: 4px;
    }

    &::-webkit-progress-value {
      background-color: var(--color-brand);
      border-radius: 4px;
    }
  `,
  seeking: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 48px;
    height: 48px;
    background-color: var(--color-static-gray100);
  `,
  spinner: css`
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--color-static-gray62);
    mask-image: url("data:image/svg+xml, %3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'  viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Cpath fill='%23000' d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  `,
  time: css`
    align-self: flex-end;
    color: var(--color-static-gray100);
  `,
}
