import Question from "source/registrations/questions/Question"
import { object, func, bool } from "prop-types"
import { useAnswers } from "source/registrations/hooks/useAnswers"
import { Heading } from "@planningcenter/doxy-web"
import { AlertMessage } from "source/shared/components"
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react"
import useUnsavedChangesAlert from "source/shared/hooks/useUnsavedChangesAlert"

const NamedAttendeeQuestions = forwardRef(
  ({ attendeeSummary, registration, isEditing, onChange, onSave }, ref) => {
    const attendeeAnswers = registration.attendees.filter((at) => {
      return at.id == attendeeSummary.id
    })[0].answers

    const { answers, questions, handleChange, handleSave, handleReset, error } =
      useAnswers({
        ...registration,
        questions: attendeeSummary.questions,
        attendeeId: attendeeSummary.id,
        answers: attendeeAnswers,
      })
    const [changes, setChanges] = useState(false)

    useUnsavedChangesAlert(changes)

    const attendeeQuestions = questions.filter(
      (q) => q.questionGroup == "attendee",
    )

    const handleSaveClick = useCallback(() => {
      if (!changes) {
        Object.keys(answers).map((k) => {
          !answers[k].isModified && onSave({ [k]: true })
        })
      } else {
        const response = handleSave()
        !response.errors && setChanges(false)
        Object.keys(answers).map((k) => {
          answers[k].isModified && onSave({ [k]: true })
        })
      }
    }, [answers, changes, onSave, handleSave])

    useImperativeHandle(ref, () => {
      return {
        save() {
          handleSaveClick()
        },
      }
    }, [handleSaveClick])

    const handleChangeMade = (questionId, value) => {
      handleChange(questionId, value, attendeeSummary.id)
      setChanges(true)
      onChange({ [questionId]: true })
    }

    useEffect(() => {
      if (changes && !isEditing) {
        setChanges(false)
        handleReset()
      }
    }, [isEditing, changes, handleReset])

    return attendeeQuestions.length == 0 ? null : (
      <>
        <div className="mt-3 mb-1">
          <Heading level={3} size={4} text="Attendee Questions" />
        </div>
        {error && (
          <AlertMessage type="error" icon="general#exclamation-triangle">
            {error}
          </AlertMessage>
        )}
        {questions.map((question) => (
          <Question
            key={question.id}
            question={question}
            answers={answers}
            onChange={handleChangeMade}
            isEditing={isEditing}
            removeContainer={true}
          />
        ))}
      </>
    )
  },
)

NamedAttendeeQuestions.propTypes = {
  attendeeSummary: object,
  registration: object,
  isEditing: bool,
  isSaving: bool,
  onChange: func,
  onSave: func,
}

NamedAttendeeQuestions.displayName = "NamedAttendeeQuestions"

export default NamedAttendeeQuestions
