import {
  createContext,
  createRef,
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { get as dig } from "lodash"
import PropTypes from "prop-types"
import Color from "color"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { LoadingFullScreen } from "source/shared/components"
import { extractMenuItems } from "source/shared/extractMenuItems"
import {
  removeLocalStorageValue,
  useLocalStorage,
} from "source/shared/hooks/useLocalStorage"

const NOOP = () => null
const BOOTING = "BOOTING"

export const WebBootContext = createContext()

const resetCacheRef = createRef()
resetCacheRef.current = NOOP

export function resetCache() {
  resetCacheRef.current()
}
WebBoot.propTypes = {
  defaultState: PropTypes.object,
  children: PropTypes.node.isRequired,
}
export function WebBoot({ defaultState = "BOOTING", children }) {
  const LOCAL_STORAGE_KEY = "WebBoot.v6"
  const DRAFT_MODE_KEY = "WebBoot.draftMode"
  const navigate = useNavigate()
  const [state, setState] = useLocalStorage(LOCAL_STORAGE_KEY, defaultState)
  const [draftMode, setDraftMode] = useLocalStorage(DRAFT_MODE_KEY, false)
  const mode = draftMode ? "draft" : "published"
  const themeColor = dig(state, `${mode}ThemeSettings.attributes.primary_color`)
  const darkThemeColor = dig(
    state,
    `${mode}ThemeSettings.attributes.dark_primary_color`,
  )
  const themingEnabled = dig(
    state,
    `${mode}ThemeSettings.attributes.theme_enabled`,
  )
  const webBootIncludes = [
    "draft_menu",
    "draft_home_page",
    "draft_theme_settings",
    "published_menu",
    "published_home_page",
    "published_theme_settings",
    "current_organization",
    "current_person",
  ]
  const WEB_BOOT_PATH = `/publishing/v2/web_boot?include=${webBootIncludes}`
  const draftModeRequested = useRef(
    window.location.search.includes("start_draft_mode=1"),
  )

  const isPublishingAdmin = dig(
    state,
    "currentPerson.attributes.publishing_administrator",
  )

  useEffect(() => {
    if (draftModeRequested.current && isPublishingAdmin) {
      setDraftMode(true)
      draftModeRequested.current = false
    }
  }, [isPublishingAdmin])

  // Make setDraftMode available to React Native
  useEffect(() => {
    if (window.ReactNativeWebView) window.setDraftMode = setDraftMode
  }, [!!window.ReactNativeWebView, setDraftMode])

  useEffect(() => {
    if (themingEnabled && themeColor) {
      document.body.style.setProperty("--color-brand-light", themeColor)
      document.body.style.setProperty(
        "--color-brand-dark",
        darkThemeColor || themeColor,
      )
      document.body.style.setProperty(
        "--color-brand-gradient-start",
        "var(--color-brand)",
      )
      document.body.style.setProperty(
        "--color-brand-gradient-end",
        "var(--color-brand)",
      )
    }

    setMyReplyBackgroundColor()
  }, [themingEnabled, themeColor, darkThemeColor])

  useEffect(() => {
    if (window.location.search.includes("start_draft_mode=1")) {
      let nextUrl = new URL(window.location.toString())
      nextUrl.searchParams.delete("start_draft_mode")
      nextUrl = nextUrl.toString().substring(nextUrl.origin.length) // relative url

      navigate(nextUrl.toString(), {
        state: { ...history.state },
        replace: true,
      })
    }
  }, [draftMode])

  const [retryWait, setRetryWait] = useState(250)
  const [eventCount, setEventCount] = useState(0)

  // Cleanup the old state shapes
  useEffect(() => {
    removeLocalStorageValue("WebBoot.v1")
    removeLocalStorageValue("WebBoot.v2")
    removeLocalStorageValue("WebBoot.v3")
    removeLocalStorageValue("WebBoot.v4")
    removeLocalStorageValue("WebBoot.v5")
  }, [])

  useEffect(() => {
    let fresh = true
    let timeout

    sessionApiClient
      .get(WEB_BOOT_PATH)
      .then((json) => {
        if (fresh) setState(mapApiResponseToStateShape(json))
      })
      .catch((error) => {
        console.log(error) // eslint-disable-line no-console
        timeout = window.setTimeout(
          () => setRetryWait(retryWait + 100),
          retryWait,
        )
      })

    return () => {
      fresh = false
      if (timeout) window.clearTimeout(timeout)
    }
  }, [eventCount, retryWait])

  const resetCache = useCallback(() => {
    removeLocalStorageValue(LOCAL_STORAGE_KEY)
    removeLocalStorageValue(DRAFT_MODE_KEY)
  })

  useEffect(() => {
    resetCacheRef.current = resetCache
    return () => (resetCacheRef.current = NOOP)
  }, [resetCache])

  const reactToCandidateEvents = useCallback(
    (event) => {
      if (
        dig(event, "type") === "Page" &&
        dig(event, "relationships.slug.data.id") !== "home"
      )
        return

      setEventCount((prev) => prev + 1)
    },
    [eventCount, setEventCount],
  )

  const contextValue = {
    ...state,
    draftMode,
    reactToCandidateEvents,
    setDraftMode,
  }

  return state === BOOTING ? (
    <LoadingFullScreen />
  ) : (
    <WebBootContext.Provider value={contextValue}>
      {children}
    </WebBootContext.Provider>
  )
}

function setMyReplyBackgroundColor() {
  const brandColor = getComputedStyle(document.body)
    .getPropertyValue("--color-brand")
    .trim()
  const hslValue = Color(brandColor).hsl().lightness(95).string()

  document.body.style.setProperty("--color-my-reply-background", hslValue)
}

function mapApiResponseToStateShape(json) {
  const extractRelationship = (name) => {
    const type = dig(json, `data.relationships.${name}.data.0.type`)
    const id = dig(json, `data.relationships.${name}.data.0.id`)
    return json.included.find((i) => i.type === type && i.id === id)
  }

  // Note: When modifying this object be sure to increment the localStorage cache number and remove the old cache
  return {
    abilities: dig(json, "data.attributes.abilities"),
    builtInPages: dig(json, "data.attributes.built_in_pages"),
    currentOrganization: extractRelationship("current_organization"),
    currentPerson: extractRelationship("current_person"),
    draftHomePage: extractRelationship("draft_home_page"),
    draftMenu: extractRelationship("draft_menu"),
    draftThemeSettings: extractRelationship("draft_theme_settings"),
    features: dig(json, "data.attributes.features"),
    joltWebsocketUrl: dig(json, "data.attributes.jolt_websocket_url"),
    myChurchCenterEnabled: dig(
      json,
      "data.attributes.my_church_center_enabled",
    ),
    publishedHomePage: extractRelationship("published_home_page"),
    publishedMenu: extractRelationship("published_menu"),
    publishedThemeSettings: extractRelationship("published_theme_settings"),
  }
}

export function useCustomNavigationLabel(fallback) {
  const state = useContext(WebBootContext)
  const { pathname } = useLocation()
  const menuItems = state.draftMode
    ? extractMenuItems(state.draftMenu)
    : extractMenuItems(state.publishedMenu)
  const customTitle = menuItems.find((i) =>
    pathname.startsWith(i.href),
  )?.children
  return customTitle || fallback
}
