import { useParams } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"
import { SeriesLibrary } from "source/publishing/sermons/SeriesLibrary"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import useNotifyNativeLoaded from "source/shared/hooks/useNotifyNativeLoaded"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { useQRCode } from "source/shared/QRCode"
import { Heading } from "@planningcenter/doxy-web"
import { Error404 } from "source/shared/components/Error404"

export function SeriesList() {
  const { channelId } = useParams()
  const response = useApiRead(`/publishing/v2/channels/${channelId}/`)

  if (response.errors?.[0]?.status === "404") return <Error404 />

  const channel = response.data
  useNotifyNativeLoaded()
  useQRCode()

  return (
    <div className="d-f fd-c g-2">
      <OpenGraphTags title={`${channel.attributes.name} Sermon Series`} />
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl={`/channels/${channelId}`}
          pageText={channel.attributes.name}
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="Series" isActive />
      </BreadcrumbNavigation>
      <Heading level={1} text="Series" />
      <SeriesLibrary
        channelId={channelId}
        queryParams={{
          filter: "published",
          order: "-ended_at",
          "where[episodes_count][gte]": 1,
        }}
      />
    </div>
  )
}
