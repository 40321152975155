import PropTypes from "prop-types"
import { Symbol } from "@planningcenter/symbol"
import checkIns from "@planningcenter/icons/sprites/check-ins.svg"
import churchCenter from "@planningcenter/icons/sprites/church-center.svg"
import general from "@planningcenter/icons/sprites/general.svg"
import logomark from "@planningcenter/icons/sprites/logomark.svg"
import people from "@planningcenter/icons/sprites/people.svg"
import publishing from "@planningcenter/icons/sprites/publishing.svg"
import brand from "@planningcenter/icons/sprites/brand.svg"
import services from "@planningcenter/icons/sprites/services.svg"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBell,
  faBook,
  faCalendar,
  faChurch,
  faComment,
  faEnvelope,
  faExpandArrowsAlt,
  faGlobe,
  faHands,
  faHandsHelping,
  faHeart,
  faInfoCircle,
  faLink,
  faListUl,
  faMapMarkerAlt,
  faMicrophone,
  faMoneyBill,
  faMusic,
  faPhone,
  faPodcast,
  faPrayingHands,
  faQuestionCircle,
  faRss,
  faSchool,
  faStar,
  faStickyNote,
  faToolbox,
  faTv,
  faUserCircle,
  faUserPlus,
  faUsers,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

library.add(
  faBell,
  faBook,
  faCalendar,
  faChurch,
  faComment,
  faEnvelope,
  faExpandArrowsAlt,
  faFacebookF,
  faGlobe,
  faHands,
  faHandsHelping,
  faHeart,
  faInfoCircle,
  faInstagram,
  faLink,
  faLink,
  faListUl,
  faMapMarkerAlt,
  faMicrophone,
  faMoneyBill,
  faMoneyBill,
  faMusic,
  faPhone,
  faPodcast,
  faPrayingHands,
  faQuestionCircle,
  faRss,
  faSchool,
  faStar,
  faStickyNote,
  faToolbox,
  faTv,
  faTwitter,
  faUserCircle,
  faUserPlus,
  faUserPlus,
  faUsers,
  faVideo,
  faYoutube,
)

const icons = {
  brand,
  general,
  "check-ins": checkIns,
  logomark,
  people,
  publishing,
  churchCenter,
  services,
}

export function Icon({ symbol: s, pathPrefix = "", ...restProps }) {
  const isFontAwesome = /^fa-/.test(s)
  const [collection, symbol] = s.replace(".svg", "").split("#")

  if (isFontAwesome) {
    const brandIcons = [
      "fa-facebook-f",
      "fa-instagram",
      "fa-twitter",
      "fa-youtube",
    ]
    return (
      <FontAwesomeIcon
        icon={brandIcons.includes(s) ? `fab ${s}` : `fa-solid ${s}`}
        {...restProps}
      />
    )
  }

  return (
    <Symbol
      symbol={`${pathPrefix}${icons[collection]}#${symbol}`}
      {...restProps}
    />
  )
}
Icon.propTypes = {
  symbol: PropTypes.string.isRequired,
  pathPrefix: PropTypes.string,
}
