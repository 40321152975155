import _ from "lodash"
import { Link } from "react-router-dom"
import { array, bool, func, arrayOf, shape, string, number } from "prop-types"
import { date as formatDate } from "@churchcenter/datetime-fmt"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Subheading, Heading } from "@planningcenter/doxy-web"
import UpcomingSchedules from "./UpcomingSchedules"
import Blockout from "./Blockout"
import UpcomingGroupsEvent from "./UpcomingGroupsEvent"
import CalendarBookmark from "./CalendarBookmark"
import RegistrationsAttendee from "./RegistrationsAttendee"
import ScheduleRequest from "./ScheduleRequest"
import SignupSheet from "./SignupSheet"
import PendingGroupsEventType from "./PendingGroupsEvent"
import ServicesOptionsDropdown from "source/schedule/ServicesOptionsDropdown"

const BLOCKOUT_TYPE = "Blockout"
const CALENDAR_BOOKMARK_TYPE = "CalendarBookmark"
const REGISTRATIONS_ATTENDEE_TYPE = "RegistrationsAttendee"
const UPCOMING_GROUPS_EVENT_TYPE = "UpcomingGroupsEvent"
const UPCOMING_SCHEDULES_TYPE = "UpcomingSchedules"

const PENDING_GROUPS_EVENT_TYPE = "PendingGroupsEvent"
const SCHEDULE_REQUEST_TYPE = "ScheduleRequest"
const SIGNUP_SHEET_TYPE = "SignupSheet"

EmptyState.propTypes = {
  hasCalendar: bool,
  hasGroups: bool,
  hasRegistrations: bool,
  hasServices: bool,
}

function EmptyState({ hasServices, hasGroups, hasCalendar, hasRegistrations }) {
  function generateEmptyStateText() {
    if (hasCalendar && hasRegistrations && hasGroups && hasServices) {
      return "Upcoming events you bookmark, register for, along with your group events and serving schedule, will appear here"
    } else if (!hasCalendar && !hasRegistrations && !hasGroups && hasServices) {
      return "Your serving schedule will appear here"
    } else if (!hasCalendar && !hasRegistrations && hasGroups && !hasServices) {
      return "Upcoming group events will appear here"
    } else if (!hasCalendar && !hasRegistrations && hasGroups && hasServices) {
      return "Upcoming group events and your serving schedule will appear here"
    } else if (!hasCalendar && hasRegistrations && !hasGroups && !hasServices) {
      return "Upcoming events you register for will appear here"
    } else if (!hasCalendar && hasRegistrations && !hasGroups && hasServices) {
      return "Upcoming events you register for, along with your serving schedule, will appear here"
    } else if (!hasCalendar && hasRegistrations && hasGroups && !hasServices) {
      return "Upcoming events you register for, along with your group events, will appear here"
    } else if (!hasCalendar && hasRegistrations && hasGroups && hasServices) {
      return "Upcoming events you register for, along with group events and serving schedule, will appear here"
    } else if (hasCalendar && !hasRegistrations && !hasGroups && !hasServices) {
      return "Upcoming events you bookmark will appear here"
    } else if (hasCalendar && !hasRegistrations && !hasGroups && hasServices) {
      return "Upcoming events you bookmark, along with your serving schedule will appear here"
    } else if (hasCalendar && !hasRegistrations && hasGroups && !hasServices) {
      return "Upcoming events you bookmark, along with your group events, will appear here"
    } else if (hasCalendar && !hasRegistrations && hasGroups && hasServices) {
      return "Upcoming events you bookmark, along with your group events and serving schedule, will appear here"
    } else if (hasCalendar && hasRegistrations && !hasGroups && !hasServices) {
      return "Upcoming events you bookmark and register for will appear here"
    } else if (hasCalendar && hasRegistrations && !hasGroups && hasServices) {
      return "Upcoming events you bookmark, register for, along with your serving schedule, will appear here"
    } else if (hasCalendar && hasRegistrations && hasGroups && !hasServices) {
      return "Upcoming events you bookmark, register for, along with your group events, will appear here"
    } else if (
      !hasCalendar &&
      !hasRegistrations &&
      !hasGroups &&
      !hasServices
    ) {
      return "Upcoming activity will appear here"
    }
  }

  return (
    <div className="action-drawer mb-1">
      {generateEmptyStateText()}
      <br />
      <Link to={hasCalendar ? "/calendar" : "/registrations"}>
        <button className="md-btn secondary-btn btn mt-2">
          Explore upcoming events
        </button>
      </Link>
    </div>
  )
}

function activityDateName({
  starts_at,
  starts_at_time_zone,
  ends_at,
  ends_at_time_zone,
}) {
  const inCurrentYear =
    new Date().getFullYear() === new Date(ends_at).getFullYear()

  if (starts_at_time_zone === ends_at_time_zone) {
    return formatDate(starts_at, ends_at, {
      style: "long",
      year: !inCurrentYear,
      timeZone: starts_at_time_zone,
    })
  } else {
    return formatDate(starts_at, {
      style: "long",
      year: !inCurrentYear,
      timeZone: starts_at_time_zone,
    })
  }
}

const SCHEDULE_COMPONENTS = {
  [UPCOMING_SCHEDULES_TYPE]: UpcomingSchedules,
  [BLOCKOUT_TYPE]: Blockout,
  [UPCOMING_GROUPS_EVENT_TYPE]: UpcomingGroupsEvent,
  [CALENDAR_BOOKMARK_TYPE]: CalendarBookmark,
  [REGISTRATIONS_ATTENDEE_TYPE]: RegistrationsAttendee,
  [SCHEDULE_REQUEST_TYPE]: ScheduleRequest,
  [SIGNUP_SHEET_TYPE]: SignupSheet,
  [PENDING_GROUPS_EVENT_TYPE]: PendingGroupsEventType,
}

function renderItem(item, refreshMyChurchCenter = null) {
  const Component = SCHEDULE_COMPONENTS[item.type]

  if (refreshMyChurchCenter) {
    return Component ? (
      <Component
        key={`${item.type}-${item.id}`}
        refreshMyChurchCenter={refreshMyChurchCenter}
        {...item}
      />
    ) : null
  } else {
    return Component ? (
      <Component key={`${item.type}-${item.id}`} {...item} />
    ) : null
  }
}

const pendingItemsDataType = shape({
  id: number,
  plan_id: number,
  schedules: array,
  service_type_id: number,
  service_type_name: string,
  time_zone: string,
  title_dates: arrayOf(string),
  type: string,
})

const activityDateDataType = shape({
  activities: array,
  ends_at: string,
  ends_at_time_zone: string,
  starts_at: string,
  starts_at_time_zone: string,
  type: string,
})

const scheduleItemsDataType = shape({
  activityDates: arrayOf(activityDateDataType),
  pendingItems: arrayOf(pendingItemsDataType),
})

Schedule.propTypes = {
  hasCalendar: bool.isRequired,
  hasGroups: bool.isRequired,
  hasRegistrations: bool.isRequired,
  hasServices: bool.isRequired,
  refreshMyChurchCenter: func.isRequired,
  scheduleItems: scheduleItemsDataType,
}

export default function Schedule({
  hasCalendar,
  hasGroups,
  hasRegistrations,
  hasServices,
  refreshMyChurchCenter,
  scheduleItems,
}) {
  if (!hasGroups && !hasServices && !hasCalendar && !hasRegistrations)
    return null

  const { activityDates, pendingItems } = scheduleItems

  const emptyState = pendingItems?.length === 0 && activityDates?.length === 0

  const scheduleRequests = _.filter(pendingItems, {
    type: "ScheduleRequest",
  })
  const schedulesToAccept = _.flatMap(scheduleRequests, "schedules")
  const showAcceptAllSchedules = _.some(schedulesToAccept)

  const handleAcceptAllScheduleRequests = () => {
    Promise.all(
      schedulesToAccept.map((schedule) =>
        sessionApiClient.post(
          `/services/v2/me/unscoped_schedules/${schedule.id}/accept`,
          {},
        ),
      ),
    )
      .then(() => {
        refreshMyChurchCenter()
      })
      .catch(() => {
        alert("Oops, something went wrong. Please try again.")
      })
  }

  function Events() {
    const maxEventsToShow = 5
    const showMaxFiveEventsMinusRequests = maxEventsToShow - pendingItems.length

    return _.take(activityDates, showMaxFiveEventsMinusRequests).map(
      (activityDate) => (
        <div
          key={`${activityDate.starts_at}-${activityDate.ends_at}`}
          className="d-f fd-c mb-2"
        >
          <div className="mb-2">
            <Subheading level={3} text={activityDateName(activityDate)} />
          </div>
          {activityDate.activities.map(renderItem)}
        </div>
      ),
    )
  }

  function Requests() {
    return (
      <div
        className="d-g mb-2"
        css={{
          gridTemplateColumns: "repeat(auto-fill,minmax(240px,1fr))",
          gridGap: "16px",
        }}
      >
        {pendingItems.map((request) =>
          renderItem(request, refreshMyChurchCenter),
        )}
      </div>
    )
  }

  return (
    <div css={{ marginBottom: "25px" }}>
      <div className="d-f ai-c jc-sb mb-1">
        <Heading level={2} text="Schedule" />
        <ServicesOptionsDropdown
          handleAcceptAllScheduleRequests={handleAcceptAllScheduleRequests}
          hasServicesAbilities={hasServices}
          showAcceptAllSchedules={showAcceptAllSchedules}
        />
      </div>
      {!emptyState ? (
        <>
          <Requests />
          <Events />
          <Link className="fw-500" to="/schedule">
            View all
          </Link>
        </>
      ) : (
        <EmptyState
          hasCalendar={hasCalendar}
          hasGroups={hasGroups}
          hasRegistrations={hasRegistrations}
          hasServices={hasServices}
        />
      )}
    </div>
  )
}
