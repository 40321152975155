import { useContext } from "react"
import { oneOfType, string, object } from "prop-types"
import { get as dig } from "lodash"
import { WebBootContext } from "source/publishing/WebBoot"
import { date, time, datetime, visualized } from "@planningcenter/datetime-fmt"
import moment from "moment"
import { buildUrl } from "@planningcenter/cc-url"

export function useConfiguration(invocationConfiguration) {
  const context = useContext(WebBootContext)
  const globalConfiguration =
    dig(context, "currentOrganization.attributes.datetime_fmt_configuration") ||
    {}
  return Object.assign({}, globalConfiguration, invocationConfiguration)
}

Date.propTypes = {
  start: oneOfType([string, object]).isRequired,
  end: oneOfType([string, object]),
}
export function Date({ start, end, ...rest }) {
  const configuration = useConfiguration(rest)
  return <time>{date(start, end, configuration)}</time>
}

VisualizedDate.propTypes = {
  start: oneOfType([string, object]).isRequired,
  end: oneOfType([string, object]),
}
export function VisualizedDate({ start, end, ...rest }) {
  const configuration = useConfiguration(rest)
  const [month, day] = visualized(start, end, configuration).split(" ")

  return (
    <div className="event-placeholder h-100%">
      <time className="event-placeholder__dates">
        <div>
          <div className="event-placeholder-month">{month}</div>
          <div className="event-placeholder-day">{day}</div>
        </div>
      </time>
      <img
        src={buildUrl("/static/no-event-image.png")}
        alt="event logo with dates"
      />
    </div>
  )
}

SmallVisualizedDate.propTypes = {
  start: oneOfType([string, object]).isRequired,
  end: oneOfType([string, object]),
}
export function SmallVisualizedDate({ start, end, ...rest }) {
  const configuration = useConfiguration(rest)
  const [month, day] = visualized(start, end, configuration).split(" ")

  return (
    <time className="event-detail__cal">
      <div className="ta-c event-cal">
        <div className="event-cal__month">{month}</div>
        <div className="event-cal__day">{day}</div>
      </div>
    </time>
  )
}

Time.propTypes = {
  start: oneOfType([string, object]).isRequired,
  end: oneOfType([string, object]),
}
export function Time({ start, end, ...rest }) {
  const configuration = useConfiguration(rest)
  return <time>{time(start, end, configuration)}</time>
}

Datetime.propTypes = {
  start: oneOfType([string, object]).isRequired,
  end: oneOfType([string, object]),
}
export function Datetime({ start, end, ...rest }) {
  const configuration = useConfiguration(rest)
  return <time>{datetime(start, end, configuration)}</time>
}

Daytime.propTypes = {
  start: oneOfType([string, object]).isRequired,
  end: oneOfType([string, object]),
}
export function Daytime({ start, end, ...rest }) {
  const configuration = useConfiguration(rest)
  return (
    <time>
      {date(start, end, configuration).split(",")[0]}
      {", "}
      {time(start, end, configuration)}
    </time>
  )
}

DayOfWeekAndTimeOfDay.propTypes = {
  start: oneOfType([string, object]).isRequired,
  end: oneOfType([string, object]),
}

export function DayOfWeekAndTimeOfDay({ start, end }) {
  const configuration = useConfiguration({ style: "long" })
  const dayOfWeek = date(start, end, configuration).split(",")[0]
  const timeOfDay = time(start, end, configuration).split(" ")[0]

  return (
    <time>
      {dayOfWeek} at {timeOfDay}
    </time>
  )
}

export function EventDatetimeDisplay({ startsAt, endsAt }) {
  const currentYear = moment().year() === moment(endsAt).year()
  const multiDayEvent = !moment(startsAt).isSame(endsAt, "day")
  const dateStyle = multiDayEvent ? "standard" : "long"

  return (
    <Datetime
      start={startsAt}
      end={endsAt}
      year={!currentYear}
      style={dateStyle}
    />
  )
}

EventDatetimeDisplay.propTypes = {
  startsAt: string,
  endsAt: string,
}
