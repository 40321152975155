import { array, func, string, bool } from "prop-types"
import { questionShape } from "source/registrations/propTypes"
import { Checkbox } from "source/registrations/CheckableInput"
import classNames from "classnames"

const QuestionCheckbox = ({
  fieldId,
  question,
  onChange,
  answers = [],
  removeContainer,
}) => {
  const allowMultiple = question.allowMultiple || question.multipleSelect

  const handleChange = ({ target: { _, checked } }) =>
    onChange(question.id, checked ? ["true"] : ["false"])

  const handleMultipleChange = ({ target: { value, checked } }) => {
    let values = checked
      ? [...answers, value]
      : answers.filter((a) => a !== value)

    onChange(question.id, values)
  }

  return (
    <div className={classNames("mb-1", { "action-drawer": !removeContainer })}>
      {!allowMultiple && (
        <div>
          <Checkbox
            id={fieldId}
            name={fieldId}
            label={question.question}
            value={"true"}
            checked={answers[0] === "true" ? true : false}
            onChange={handleChange}
          />
          {(question.required || question.publiclyRequired) && (
            <span className="c-ruby required-symbol"> *</span>
          )}
          {question.description && (
            <p className="fs-4 c-tint2 mt-1">{question.description}</p>
          )}
        </div>
      )}

      {allowMultiple && (
        <div>
          <div>
            <div className={`label ${question.description && "pb-0"}`}>
              {question.question}
              {(question.required || question.publiclyRequired) && (
                <span className="c-ruby required-symbol"> *</span>
              )}
            </div>
            {question.description && (
              <p className="fs-4 mb-4p">{question.description}</p>
            )}
          </div>
          {question.choices.map((choice) => (
            <div className="mt-4p" key={choice}>
              <Checkbox
                label={choice}
                id={`${fieldId}_${choice}`}
                name={`${fieldId}_${choice}`}
                value={choice}
                checked={answers.includes(choice) || false}
                onChange={handleMultipleChange}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

QuestionCheckbox.propTypes = {
  question: questionShape.isRequired,
  onChange: func.isRequired,
  fieldId: string.isRequired,
  answers: array,
  removeContainer: bool,
}

export default QuestionCheckbox
