import {
  useQuery,
  splitQueryParams,
  queryFn,
} from "source/registrations/utils/queryCache"
import { useEnv } from "source/shared/hooks/useEnv"

export const useEventFeature = (name, eventId, options = {}) => {
  const railsEnv = useEnv()
  const path = `/registrations/v2/events/${eventId}/features`
  const { params, queryOptions } = splitQueryParams(options)

  const response = useQuery({
    queryFn,
    queryKey: [path, { fields: { Feature: "name,state,enabled" }, ...params }],
    ...queryOptions,
  })

  const data = response?.data || []

  const feature = data.find(
    (feature) => feature?.name === `${name}_${railsEnv}`,
  )

  return feature?.enabled || false
}
