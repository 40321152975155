import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { channelPath } from "source/publishing/sermons/shared/routes"
import { useQRCode } from "source/shared/QRCode"
import useNotifyNativeLoaded from "source/shared/hooks/useNotifyNativeLoaded"
import {
  WebBootContext,
  useCustomNavigationLabel,
} from "source/publishing/WebBoot"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { LimitedInfiniteScroll, Card, CardGrid } from "source/shared/components"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { unionBy } from "lodash"

ChannelList.propTypes = { queryParams: PropTypes.object }
export function ChannelList({ queryParams = {} }) {
  const perPage = 30
  const loadManuallyAfterThisOffset = 60
  const [offset, setOffset] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [channels, setChannels] = useState([])

  const { draftMode } = useContext(WebBootContext)

  const loadMore = () => {
    setHasMore(false)
    setOffset((previousOffset) => previousOffset + perPage)
  }

  const allParams = Object.assign({}, queryParams, {
    filter: draftMode ? "" : "published",
    order: "position",
    per_page: perPage,
    offset,
  })
  const queryString = new URLSearchParams(allParams).toString()

  useEffect(() => {
    setLoadingMore(true)
    sessionApiClient
      .get(`/publishing/v2/channels?${queryString}`)
      .then((payload) => {
        setLoadingMore(false)
        setHasMore(!!payload.links.next)
        setChannels((prev) => unionBy(prev, payload.data, "id"))
      })
      .catch((error) => {
        setLoadingMore(false)
        console.log(`There was an error fetching more channels: ${error}`) // eslint-disable-line no-console
      })
  }, [queryString])

  useQRCode()
  useNotifyNativeLoaded()
  const title = useCustomNavigationLabel("Sermons")

  return (
    <div>
      <OpenGraphTags title={title} />
      <LimitedInfiniteScroll
        loadMoreButtonText="View more"
        keepLoadingOnScroll={offset < loadManuallyAfterThisOffset}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
      >
        <CardGrid>
          {channels.map((channel) => (
            <>
              <Card
                link={{
                  as: Link,
                  to: channelPath(channel),
                }}
                poster={
                  channel.attributes.art &&
                  channel.attributes.art.attributes.variants.small
                }
                heading={channel.attributes.name}
                description={channel.attributes.description}
              />
            </>
          ))}
        </CardGrid>
      </LimitedInfiniteScroll>
    </div>
  )
}
