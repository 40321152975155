import { useEffect, useCallback, useState, useContext, useMemo } from "react"

import { sessionApiClient } from "@planningcenter/cc-api-client"
import { time } from "@churchcenter/datetime-fmt"
import { useApiRead } from "source/shared/SessionApiResource"
import { useConfiguration as useDateTimeConfiguration } from "source/shared/DatetimeFmt"

import calendarEventDetails from "source/calendar/utils/calendarEventDetails"
import { CalendarDataContext } from "source/calendar/CalendarDataContext"
import { useMomentInTimeZone } from "source/calendar/hooks/useMomentInTimeZone"
import { groupBy, sortBy, filter, every } from "lodash/collection"
import { cloneDeep, isEmpty } from "lodash/lang"
import { mapValues } from "lodash/object"
import { chain, minBy } from "lodash"

const MONTH_VIEW_FIELDS = [
  "id",
  "name",
  "starts_at",
  "ends_at",
  "event_time",
  "all_day_event",
  "multi_day_event",
  "category_tags",
  "campus_tags",
  "event_registration_url",
  "featured",
  "event_group",
  "image_url",
]

export const GALLERY_VIEW_EVENT_FIELDS = [
  "id",
  "name",
  "starts_at",
  "ends_at",
  "event_time",
  "all_day_event",
  "multi_day_event",
  "category_tags",
  "campus_tags",
  "event_registration_url",
  "featured",
  "image_url",
  "recurrence_description",
  "initial_starts_at",
  "event_group",
]

export const LOCATION_BASED_EVENT_INCLUDES =
  "location,event_registration_url,category_tags,campus_tags"

const LIST_VIEW_PARAMS = [
  `include=${LOCATION_BASED_EVENT_INCLUDES}`,
  "filter=upcoming",
  "per_page=50",
]

const GALLERY_VIEW_PARAMS = [
  `include=${LOCATION_BASED_EVENT_INCLUDES}`,
  "filter=upcoming,first_occurrence",
  `fields[Event]=${GALLERY_VIEW_EVENT_FIELDS.join()}`,
  "order=-featured,visible_starts_at",
  "per_page=25",
]

const MONTH_VIEW_PARAMS = [
  "include=event_registration_url,category_tags,campus_tags",
  `fields[Event]=${MONTH_VIEW_FIELDS.join()}`,
  "per_page=100",
]

const FEATURED_VIEW_PARAMS = [
  `include=${LOCATION_BASED_EVENT_INCLUDES}`,
  "filter=upcoming,first_occurrence",
  "order=visible_starts_at",
  "where[featured]=1",
  "per_page=25",
]

export function useCalendarEvents(startDate, viewingMonth, viewData) {
  const { data, addEvents, addRequestedUrl } = useContext(CalendarDataContext)
  const {
    events,
    links: allLinks,
    included,
    requestedUrls,
    lastDateLoaded: allLoadedDates,
  } = data
  const momentInTZ = useMomentInTimeZone()
  const params = new URLSearchParams(window.location.search)

  const { isListView, isGalleryView, isMonthView, view } = viewData

  const endOfMonth = momentInTZ(viewingMonth).endOf("month")
  const startOfMonth = momentInTZ(viewingMonth).startOf("month")
  const paddedEndDate = endOfMonth
    .add(6 - endOfMonth.day(), "days")
    .endOf("day")

  const paddedStartDate = startOfMonth
    .subtract(startOfMonth.day(), "days")
    .startOf("day")

  const beginningDate = isMonthView ? paddedStartDate : startDate

  const dateTimeConfiguration = useDateTimeConfiguration()
  const [loadingEvents, setLoadingEvents] = useState(true)

  const categories = useApiRead("/calendar/v2/category_tags?per_page=100")
  const categoryParam = decodeURIComponent(params.get("category")).trim()
  const category =
    categories.data &&
    categories.data.find((cat) =>
      [cat.attributes.name.trim(), cat.id].includes(categoryParam),
    )

  const campuses = useApiRead("/calendar/v2/campus_tags?per_page=100")
  const campusParam = decodeURIComponent(params.get("campus")).trim()
  const campus =
    campuses.data &&
    campuses.data.find((cam) =>
      [cam.attributes.name.trim(), cam.id].includes(campusParam),
    )

  const eventParams = () => {
    const filter = []

    if (!isEmpty(category)) {
      filter.push(`where[category]=${encodeURIComponent(category.id)}`)
    }

    if (!isEmpty(campus)) {
      filter.push(`where[campus]=${encodeURIComponent(campus.id)}`)
    }

    if (isListView) {
      return LIST_VIEW_PARAMS.concat(filter)
    } else if (isGalleryView) {
      return GALLERY_VIEW_PARAMS.concat(filter)
    } else if (view === "featured") {
      return FEATURED_VIEW_PARAMS.concat(filter)
    } else {
      filter.push(
        `where[starts_at][lte]=${paddedEndDate.toISOString()}&where[ends_at][gte]=${paddedStartDate.toISOString()}`,
      )
      return MONTH_VIEW_PARAMS.concat(filter)
    }
  }

  const initialDataUrl = ["/calendar/v2/events", eventParams().join("&")].join(
    "?",
  )

  const links = allLinks[initialDataUrl] || {}
  const lastDateLoaded =
    allLoadedDates[initialDataUrl] || beginningDate.toISOString()
  const lastDateLoadedMoment = momentInTZ(lastDateLoaded)
  const hasMore = !!links.next

  const fetchEvents = useCallback(
    (url) => {
      if (!url) return

      if (requestedUrls.includes(url)) return

      addRequestedUrl(url)
      setLoadingEvents(true)

      return sessionApiClient.get(url).then((response) => {
        response.linkKey = initialDataUrl
        addEvents(response)
      })
    },
    [addEvents, addRequestedUrl, requestedUrls, initialDataUrl],
  )

  useEffect(() => {
    fetchEvents(initialDataUrl)
  }, [addRequestedUrl, initialDataUrl, fetchEvents, requestedUrls])

  // return all events needed to render list view or month view. Mainly speeds up month view rendering.
  const eventsForView = useMemo(() => {
    return filter(events, (event) => {
      const filters = []
      if (isMonthView) {
        // where[starts_at][lte]=${paddedEndDate.toISOString()}&where[ends_at][gte]=${paddedStartDate.toISOString()}`,
        filters.push(
          momentInTZ(event.attributes.starts_at) <= paddedEndDate &&
            momentInTZ(event.attributes.ends_at) >= beginningDate,
        )
      } else if (isGalleryView || view === "featured") {
        filters.push(momentInTZ(event.attributes.ends_at) >= beginningDate)
      } else if (isListView) {
        filters.push(
          momentInTZ(event.attributes.ends_at) >= beginningDate &&
            momentInTZ(event.attributes.starts_at) <= lastDateLoadedMoment,
        )
      }

      if (view === "featured") {
        filters.push(event.attributes.featured)
      }

      if (!isEmpty(campus)) {
        filters.push(
          event.relationships.campus_tags.data
            .map((it) => it.id)
            .includes(campus.id.toString()),
        )
      }

      if (!isEmpty(category)) {
        filters.push(
          event.relationships.category_tags.data
            .map((it) => it.id)
            .includes(category.id.toString()),
        )
      }

      return every(filters)
    })
  }, [
    events.length,
    isMonthView,
    isListView,
    isGalleryView,
    viewingMonth,
    beginningDate.toISOString(),
    paddedEndDate.toISOString(),
    paddedStartDate.toISOString(),
    campus,
    category,
    view,
  ])

  const loadMore = useCallback(
    () => fetchEvents(links.next),
    [fetchEvents, links.next],
  )

  const loadEventsUntilEndOfMonth = useCallback(() => {
    const shouldLoadMoreEvents = () => {
      const eventsEmpty = eventsForView.length == 0
      const hasMoreToLoad = !!links.next
      if (eventsEmpty) return false
      if (!hasMoreToLoad) return false
      if (isMonthView && hasMoreToLoad) return true

      return momentInTZ(lastDateLoaded).isBefore(paddedEndDate)
    }

    if (shouldLoadMoreEvents()) {
      loadMore()
    } else {
      const initialDataRequestComplete = !isEmpty(links)
      if (initialDataRequestComplete) setLoadingEvents(false)
    }
  }, [
    eventsForView,
    links,
    loadMore,
    momentInTZ,
    paddedEndDate,
    view,
    viewingMonth,
    lastDateLoaded,
  ])

  useEffect(() => {
    if (isEmpty(links)) return
    loadEventsUntilEndOfMonth()
  }, [
    events,
    viewingMonth,
    view,
    initialDataUrl,
    links,
    loadEventsUntilEndOfMonth,
  ])

  /**
   * Multi-day events should show on every day they occur. Let's add
   * each day that an event occurs into our list as its own entry,
   * so grouping by day Just Works.
   */
  const eventListWithEntryForEachDayOfEvent = (events) => {
    let extendedEventList = []
    events.forEach((event) => {
      if (isMultiDay(event)) {
        const eventSplitByDays = splitMultiDayEventByDay(event)
        const visibleEventDays = eventSplitByDays.filter(afterStartDate)

        extendedEventList.push(...visibleEventDays)
      } else {
        extendedEventList.push(event)
      }
    })

    return extendedEventList
  }

  const afterStartDate = (event) => {
    const { starts_at: startsAt } = event.attributes
    return momentInTZ(startsAt).isSameOrAfter(beginningDate, "day")
  }

  const isMultiDay = (event) => event.attributes.multi_day_event

  const numDaysToDisplay = (event) => {
    const { ends_at: endsAt, starts_at: startsAt } = event.attributes
    const agendaEventThatEndsAfterLastDateLoaded =
      !isMonthView && momentInTZ(lastDateLoaded).isBefore(endsAt)

    const lastDateToDisplay =
      agendaEventThatEndsAfterLastDateLoaded && hasMore // only limit to the lastDateLoaded if there are more events to load
        ? momentInTZ(lastDateLoaded)
        : momentInTZ(endsAt)
    return Math.max(daysInRange(startsAt, lastDateToDisplay, momentInTZ), 1)
  }

  const splitMultiDayEventByDay = (event) => {
    const { starts_at: startsAt } = event.attributes

    const splitEvent = [...Array(numDaysToDisplay(event))].map((_, i) => {
      const newStartsAt = momentInTZ(startsAt).add(i, "day")
      const newEvent = cloneDeep(event)
      newEvent.attributes.initial_starts_at = momentInTZ(startsAt).toISOString()
      newEvent.attributes.starts_at = newStartsAt.toISOString()

      return newEvent
    })

    return splitEvent
  }

  const firstOccurrences = useMemo(() => {
    return chain(eventsForView)
      .groupBy((event) => event.attributes.event_group)
      .mapValues((events) =>
        minBy(events, (event) => new Date(event.attributes.ends_at)),
      )
      .values() // convert hash to array
      .value() // get value from `chain`
  }, [eventsForView])

  const eventsWithDetails = useMemo(() => {
    const filteredEvents =
      isGalleryView || view === "featured"
        ? firstOccurrences
        : eventListWithEntryForEachDayOfEvent(eventsForView)
    const unsortedEvents = filteredEvents.map((event) => {
      const eventDetails = calendarEventDetails({ data: event, included })

      eventDetails.eventTime = getListEventTime({
        dateTimeConfiguration,
        startsAt: eventDetails.initialStartsAt
          ? momentInTZ(eventDetails.initialStartsAt)
          : momentInTZ(eventDetails.startsAt),
        endsAt: momentInTZ(eventDetails.endsAt),
        isAllDay: !!eventDetails.allDayEvent,
        isMultiDay: !!eventDetails.multiDayEvent,
        forDay: eventDetails.initialStartsAt ? eventDetails.startsAt : null,
        momentInTZ,
      })
      return eventDetails
    })
    return sortBy(unsortedEvents, [
      (event) => !event.featured,
      "startsAt",
      "endsAt",
      "name",
    ])
  }, [eventsForView, isGalleryView, view])

  const eventsByDay = useMemo(() => {
    return mapValues(
      groupBy(eventsWithDetails, (event) =>
        momentInTZ(event.startsAt).startOf("day").toISOString(),
      ),
      (event) => sortBy(event, ["startsAt", "endsAt", "name"]),
    )
  }, [eventsWithDetails])

  return {
    campus,
    campuses,
    categories,
    category,
    loadingEvents,
    eventsByDay,
    eventsWithDetails,
    hasMore,
    loadMore,
    lastDateLoaded,
  }
}

export function getListEventTime({
  dateTimeConfiguration,
  startsAt,
  endsAt,
  forDay = null,
  isAllDay = false,
  isMultiDay = false,
  momentInTZ,
}) {
  if (isAllDay) return "All day"

  if (isMultiDay) {
    if (momentInTZ(startsAt).isSame(forDay, "day")) {
      return `Starts ${time(startsAt, dateTimeConfiguration)}`
    } else if (momentInTZ(endsAt).isSame(forDay, "day")) {
      return `Ends ${time(endsAt, dateTimeConfiguration)}`
    } else {
      return "All day"
    }
  } else {
    return time(startsAt, endsAt, dateTimeConfiguration)
  }
}

function daysInRange(beginningDate, endDate, momentInTZ) {
  const beginningOfStartDate = momentInTZ(beginningDate).startOf("day")
  const beginningOfEndDate = momentInTZ(endDate).startOf("day")

  return beginningOfEndDate.diff(beginningOfStartDate, "days") + 1
}

export { daysInRange as testDaysInRange }
