import { func, string, bool, node, oneOfType } from "prop-types"
import classNames from "classnames"
import { useScreenWidth, BREAKPOINTS } from "source/shared/hooks/useScreenWidth"
import { Icon } from "source/shared/components"

const Field = ({
  label,
  value,
  canEdit = false,
  isRequired,
  children,
  error,
  isEditing,
  hasPersistedValue,
}) => {
  const screenWidth = useScreenWidth()

  const isSmAndDown = screenWidth <= BREAKPOINTS.SM
  const longLabel = label.length > 10

  const showStaticValue = !isEditing || (isEditing && !canEdit)
  const showEditInputs = isEditing && canEdit

  const fieldGridLine = isSmAndDown
    ? {
        display: "grid",
        gridTemplateColumns: "1fr",
        marginBottom: "16px",
      }
    : {
        display: "grid",
        gridTemplateColumns: "88px 1fr",
        columnGap: "8px",
        marginBottom: "8px",
      }

  return (
    <div css={fieldGridLine}>
      <label
        className={classNames(
          "fs-4 pb-0 fw-600",
          isEditing && isRequired ? "required-field" : "",
          error ? "c-ruby" : "c-tint2",
        )}
        css={{
          lineHeight: longLabel || isSmAndDown ? "16px" : "32px",
          marginBottom: isSmAndDown ? "4px" : 0,
        }}
      >
        {label}{" "}
        {isRequired && !hasPersistedValue && !isEditing && (
          <Icon
            symbol="general#exclamation-triangle"
            className="fs-8 symbol"
            style={{ color: "var(--color-citrine)" }}
          />
        )}
      </label>

      <div className={`${!isEditing && canEdit && "mb-1"}`}>
        {showStaticValue && (
          <div className="d-f ai-c">
            <span
              className="fs-4 c-tint2"
              css={{ lineHeight: isSmAndDown ? "16px" : "32px" }}
            >
              {value}
            </span>
          </div>
        )}

        {showEditInputs && (
          <>
            {children}
            {error && <div className="c-ruby">{error}</div>}
          </>
        )}
      </div>
    </div>
  )
}

Field.propTypes = {
  label: string,
  persistedValue: oneOfType([string, node]),
  value: oneOfType([string, node]),
  canEdit: bool,
  isRequired: bool,
  isOptional: bool,
  onEdit: func,
  onSave: func,
  onCancel: func,
  children: node,
  error: string,
  isSmallScreen: bool,
  isEditing: bool,
  hasPersistedValue: bool,
}

export default Field
