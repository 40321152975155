import { useContext } from "react"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { buildUrl } from "@planningcenter/cc-url"
import { Heading } from "@planningcenter/doxy-web"
import { NarrowLayout } from "source/Layout"

export function Error404() {
  const { layout } = useContext(ServerRenderedProps)
  const { organization_contact_email: email } = layout
  return (
    <NarrowLayout>
      <div className="ta-c">
        <img
          className="error-img pt-2 pt-0@md"
          style={{ maxWidth: "min(600px, 100vw - 40px)" }}
          src={buildUrl("/static/errors/hot-air-balloon.png")}
          alt="Error graphic"
        />
        <div className="my-3">
          <Heading level={1} text="We seem to have strayed off course" />
        </div>
        {email && (
          <>
            <div className="mt-3 mb-3 ml-a mr-a" style={{ maxWidth: "400px" }}>
              If you believe there should be something here, please contact us
              at: <a href={`mailto:${email}`}>{email}</a>
            </div>
            <a href={`mailto:${email}`} type="button" className="btn">
              Contact us
            </a>
          </>
        )}
      </div>
    </NarrowLayout>
  )
}
