import { useState, useEffect, Suspense } from "react"
import { object, func } from "prop-types"
import MakePaymentButton from "./MakePaymentButton"
import PaymentForm from "./PaymentForm"
import { useOrganization } from "source/registrations/hooks/useOrganization"
import { loadStripe } from "@stripe/stripe-js/pure"
import { Loading } from "source/shared/components"

const PaymentFormWrapper = ({
  registration,
  registrationInvoice,
  onPaymentSave,
}) => {
  const [openForm, setOpenForm] = useState(false)
  const [stripe, setStripe] = useState(null)

  const {
    data: {
      paymentIntegration: { publishableKey, accountIdentifier },
    },
  } = useOrganization()
  const { allowCreditCardPayments, hasPaymentsModule } = registration.event

  useEffect(() => {
    const fetchStripe = async () => {
      const response = await loadStripe(publishableKey, {
        stripeAccount: accountIdentifier,
      })
      setStripe(response)
    }

    if (
      hasPaymentsModule &&
      allowCreditCardPayments &&
      publishableKey &&
      !stripe
    ) {
      fetchStripe()
    }
  }, [
    publishableKey,
    accountIdentifier,
    allowCreditCardPayments,
    hasPaymentsModule,
    stripe,
  ])

  return (
    <Suspense fallback={<Loading />}>
      {!openForm && (
        <div className={"ta-c my-3"}>
          <MakePaymentButton
            balanceDueCents={registrationInvoice.balanceDueCents}
            registration={registration}
            onOpenForm={setOpenForm}
          />
        </div>
      )}

      {openForm && (
        <PaymentForm
          registration={registration}
          registrationInvoice={registrationInvoice}
          onPaymentSave={onPaymentSave}
          onOpenForm={setOpenForm}
          stripe={stripe}
        />
      )}
    </Suspense>
  )
}

PaymentFormWrapper.propTypes = {
  registration: object.isRequired,
  registrationInvoice: object.isRequired,
  onPaymentSave: func.isRequired,
}

export default PaymentFormWrapper
