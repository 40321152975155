import { useContext } from "react"
import { node, oneOf } from "prop-types"
import { CurrentGroupContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import { EventsList } from "source/groups/components/EventsList"
import { Link } from "react-router-dom"
import BlankState from "source/groups/BlankState"
import { CalendarSubscriptionButton } from "source/groups/components/CalendarSubscriptionButton"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import { Icon, ActionBar } from "source/shared/components"
import { GroupAttendanceAverageSummary } from "source/groups/my/groups/attendances/GroupAttendanceAverageSummary"
import { TabViewHeadingRow } from "source/groups/my/groups/TabViewHeadingRow"

export default function EventsIndex() {
  const group = useContext(CurrentGroupContext)
  const attendanceReportUrl = `${group.base_path}/attendances`
  const canManage = group.abilities.events.can_manage

  return (
    <TabView>
      <InfiniteEventsList filter="upcoming">
        {group.abilities.membership_attendances.can_read && (
          <ActionBar.Link to={attendanceReportUrl} className="mb-3@sm">
            <ActionBar.Title>Attendance report</ActionBar.Title>
            <ActionBar.Description>
              <GroupAttendanceAverageSummary groupId={group.id} />
            </ActionBar.Description>
          </ActionBar.Link>
        )}
        <TabViewHeadingRow text="Upcoming events">
          {canManage && (
            <Link
              to="new"
              className="minor-btn secondary-btn btn d-f ai-c"
              style={{ gap: "4px" }}
            >
              <Icon symbol="general#plus" className="fs-6" aria-hidden /> New
            </Link>
          )}
          <CalendarSubscriptionButton
            links={group.calendar_subscription_links}
            subscriptionType="feed"
            iCalUrl={group.calendar_subscription_url}
          >
            Subscribe to group calendar
          </CalendarSubscriptionButton>
        </TabViewHeadingRow>
        <EventsList.Content>
          <EventsList.List allowAttendance />
        </EventsList.Content>
        <EventsList.Empty>
          <BlankState icon="churchCenter#calendar-events">
            <p>
              There are currently no upcoming events scheduled for this group.
            </p>
          </BlankState>
        </EventsList.Empty>
      </InfiniteEventsList>
      <InfiniteEventsList filter="past">
        <EventsList.Content>
          <TabViewHeadingRow text="Past events" />
          <EventsList.List allowAttendance />
        </EventsList.Content>
      </InfiniteEventsList>
    </TabView>
  )
}

function InfiniteEventsList({ filter, children }) {
  const group = useContext(CurrentGroupContext)
  const order = filter === "upcoming" ? "starts_at" : "-starts_at"
  const { records: events = [], ...rest } = useInfiniteLoadingResource(
    `/groups/v2/me/groups/${group.id}/events?filter=${filter}&order=${order}&include=location,my_rsvp`,
  )
  const getEventUrl = (event) => event.attributes.church_center_my_event_url

  return (
    <EventsList events={events} getEventUrl={getEventUrl} {...rest}>
      {children}
    </EventsList>
  )
}
InfiniteEventsList.propTypes = {
  filter: oneOf(["upcoming", "past"]).isRequired,
  children: node,
}
