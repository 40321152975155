import {
  useQuery,
  usePrefetchQuery,
  splitQueryParams,
  queryFnAll,
} from "source/registrations/utils/queryCache"

const FIELDS = {
  Question: [
    "allow_multiple",
    "choices",
    "description",
    "excluded_attendee_types",
    "kind",
    "question_group",
    "question",
    "required",
  ],
  AttendeeType: ["id", "type"],
}

const queryOptions = (eventId, options = {}) => {
  const path = `/registrations/v2/events/${eventId}/questions`
  const { params, queryOptions } = splitQueryParams(options)

  return {
    suspense: false,
    queryFn: queryFnAll,
    queryKey: [
      path,
      {
        filter: "for_reservation",
        include: "excluded_attendee_types",
        fields: FIELDS,
        ...params,
      },
    ],
    ...queryOptions,
  }
}

export const useQuestionsPrefetch = (eventId, options) =>
  usePrefetchQuery(queryOptions(eventId, options))

export const useQuestions = (eventId, options) =>
  useQuery(queryOptions(eventId, options))
